// UI colors
$footer-background: rgba(0, 0, 0, 0.8);
$facebook: blue;
// Colors
$primary-green: #6cc788;
$primary-orange: #ffc58a;
$red: red;
$white: #fff;
$alphaWhite :  rgba(255, 255, 255, 0.6);
$black: #000;
$grey: grey;
$darkgrey: darkgrey;
$lightgrey: rgba(0, 0, 0, 0.1);
$defaultFont: rgba(0, 0, 0, 0.65);
