@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.user_profile_Banner {
  // width: 100%;
  background-color: $primary-green;
  max-height: 190px;
  min-height: 100px;
  height: 15vh;
  padding: $hv-padding $hh-padding;
  display: flex;
  align-items: center;

  .user_profile_Banner_avatar_body {
    position: relative;
    cursor: pointer;
  }
  .user_profile_Banner_avatar_icon {
    background-color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    // transform: translate(-5px, -5px);
  }
  .user_profile_Banner_avatar {
    .user_profile_Banner_avatar_body {
      width: 80px;
      height: 80px;
      .user_profile_Banner_avatar_image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  p {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0 20px;
  }
}

// @media screen and (min-width: $md-break) and (max-width: $xl-break) {

// }

@media screen and (max-width: $md-break) {
  .user_profile_Banner {
    padding: $double-gap;
    .user_profile_Banner_avatar {
      width: 50px;
      height: 50px;
    }
  }
}
