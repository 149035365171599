@import "../../../Themes/sizes";
@import "../../../Themes/colors";
#contact-form {
  position: relative;
  // width: 60%;

  .contact-form {
    width: 100%;
    .ant-input-prefix {
      left: $small-gap;
    }
    .fake-input {
      display: flex;
      flex-direction: row;
      .input-icon {
        width: 45px;
      }
      .fake-input-body {
        width: 100%;
        input {
          padding: 0 12px;
        }
      }
    }
    .input-icon {
      height: 100%;
      display: flex;
      background-color: $primary-green;
      justify-content: center;
      align-items: center;
      // height: 40px;
      // padding-top: 5px;
      // margin: auto;
      // margin-left: 3px;

      svg {
        color: white;
        font-size: $small-size;
      }
    }
    .center {
      margin-left: 12px;
    }
    .location-feild {
      height: 40px;
      border: none;
      width: 100%;
      background-color: #fff;
      margin-right: 10px;
    }
    .ant-form-item {
      .input-Border {
        box-shadow: 0px 0px 9px 3px #7e7e7e17;
        border-radius: 4px;
        overflow: hidden;
      }
      margin-bottom: 10px;
      h2,
      p {
        margin: 0px;
        color: black;
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }
      p {
        line-height: 20px;
        margin-bottom: $double-gap;
      }
      .ant-input-group-addon {
        background: $primary-green;
        border: 0;
        // width: 15%;
        box-sizing: border-box;
        text-align: left;
        .ant-input {
          height: $input-height;
          border: none;
          border-radius: 0px !important;
        }
        textarea.ant-input {
          height: 2 * $input-height;
        }
      }
      .ant-btn {
        margin-top: 10px;
        width: 150px;
        height: $input-height;
        color: white;
        border-radius: 4px !important;
        background-color: $primary-green;
        border-color: $primary-green;
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  #contact-form {
    width: 100%;

    .contact-form .ant-form-item p {
      line-height: 20px;
      margin-bottom: 20px;
      padding-top: 10px;
    }

    .contact-form .ant-form-item .ant-btn {
      margin-top: 12px;
    }
  }
}
