@import "../../Themes/sizes";
@import "../../Themes/colors";

.landing-page {
  // background-color: #f5f5f5;
  overflow: hidden;
  .landing-container {
    padding: 0 !important;
    .labelwrap {
      padding: $hh-padding/2;
      padding-left: $hh-padding;
      .header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 55px;
        color: #464646;
        margin-bottom: 20px;
      }
      .Landing-productSlider-Item {
        margin-left: 10px;
      }
      .Landing-productSlider {
        width: 100%;
        overflow: unset;

        .Cards-Product {
          // width: 200px;
          min-height: 100%;
        }
      }
    }
  }
  .landing-Image {
    width: 100%;
    height: 80vh;
    // visibility: hidden;
    display: none;
  }
  .landing-Top {
    // margin-top: -100px;
    // height: 500px;
    // background-color: #f0fff8;
    .slider-img {
      // margin-top: -100px;
      img.people {
        // display: none;
        width: 600px;
        z-index: 1;
        position: relative;
      }
      img.landing-Image-Illustration {
        position: absolute;
        // height: 500px;
        // width: 50%;
        top: 0;
        right: 0;
      }
    }

    padding: $p-header-padding 20px;
    margin: auto;
    max-width: 1300px;
    // height: 100%;
    // height: 80vh;
    // background-size: auto 100%;
    background-position: top right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .float-button {
      position: absolute;
      bottom: $bigger-gap;
      left: 50%;
      transform: translateX(-$normal-size/2);
      img {
        width: $normal-size;
        height: $normal-size;
      }
    }
  }
  .search-wrapper {
    position: relative;
    float: left;
    display: flex;
    // width: 50%;
    // width: 300px;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    height: 100%;
    z-index: 10;
    height: 100%;
    // margin: 238px 0 400px;
    .title {
      margin: 0px;
      font-size: 40px;
      max-width: 500px;
      font-weight: 700;
    }
    .colored {
      color: #3e985a;
    }
    .tagline {
      font-size: $normal-font;
      color: black;
    }
  }

  .form-wrapper {
    padding: $double-gap 0;
    max-width: 600px;

    // background-color: $primary-green;
  }
  .product-grid {
    display: grid;
    grid-gap: $big-gap;
    grid-template-columns: repeat(5, 1fr);
  }
}

// @media screen and (min-width: 300px) and (max-width: 325px) {

// }

@media screen and (min-width: $md-break) and (max-width: $xl-break) {
  .landing-page {
    .landing-Top {
      flex-direction: column-reverse;
      padding: 50px;
      .slider-img {
        img {
          width: 300px !important;
          margin-bottom: 30px;
        }
      }
    }
    background-color: white;
    .landing-container {
      .labelwrap {
        .Landing-productSlider-Item {
          margin-left: 6px;
        }
        .Landing-productSlider {
          .Cards-Product {
            width: 180px;
          }
        }
      }
    }
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: $md-break) {
  // .slider-img {
  //   display: none;
  // }

  // .landing-page {
  //   .path-width {
  //     padding-top: 20px;
  //     .labelwrap {
  //       padding-left: 9px !important;
  //       .label-container {
  //         .Landing-productSlider {
  //           ul {
  //             li {
  //               width: 180px !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .landing-page {
    .float-button {
      visibility: hidden;
    }
    .landing-container {
      .trending_buy {
        // margin-top: 5rem;

        .labelwrap {
          padding: $double-gap;
          padding-left: 7px !important;
          .Landing-productSlider {
            .Cards-Product {
              // margin: 0 20px;
              // width: 230px;
            }
          }
        }
      }
    }
    .landing-Image {
      display: block;
      max-height: 40vh;
      width: auto;
      margin-left: auto;
      margin-bottom: -75px;
    }
    .landing-Top {
      padding: 30px;
      background-size: 0px;
    }
    .search-wrapper {
      margin-top: $bigger-gap;
      gap: 30px;
      width: 100%;
      justify-content: flex-start;
      margin-bottom: $bigger-gap;
      .title {
        padding-top: 15px;
        font-size: 30px !important;
        font-weight: 700;
      }
      .search-title {
        font-size: 18px;
        margin-bottom: $base-gap;
      }
    }

    .product-grid {
      grid-gap: $base-gap;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.left-path {
  position: relative;
  z-index: 9;
  // margin-top: 100px;
  margin-bottom: 0px;
}
.trending_buy {
  // margin-top: 11rem;
}
.label-wrapper {
  position: relative;
  z-index: 9;
}

.step-section-outer {
  position: relative;
  background: #fff;
  padding-top: 50px;
}
.react-multiple-carousel__arrow--left {
  top: 540px;
  background: #6cc788 !important;
}
.react-multiple-carousel__arrow--right {
  top: 540px;
  left: 130px;
  background: #6cc788 !important;
}
.step-section {
  max-width: 992px;
  margin: auto;
  margin-top: 20px;

  position: relative;
  z-index: 9;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #464646;
    margin-top: 100px;
    margin-bottom: 20px;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    max-width: 361px;
  }
  .step-list.row {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: $md-break) {
  max-width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .step-section {
    .step-list.row {
      .FloatSmall {
        float: left !important;
      }
    }
  }
}

// .react-multi-carousel-list.Landing-productSlider {
//     overflow: unset;
// }

// .landing-page .landing-container .labelwrap {
//     overflow: hidden;
// }
li.react-multi-carousel-item {
  margin-right: 20px;
}

.video-section {
  background: #fff;
  position: relative;
  // display: flex;
  // flex-direction: column;
  padding-bottom: 50px;
  text-align: center;
  // margin-top: -8%;

  .video-section-div1 {
    width: 100%;
    // height: 151px;

    .video-section-div1-btn {
      color: #b3e1c1;
      background: #6cc788;
      margin: 10px 0px 0px 0px;
    }
  }
  .video-section-div2 {
    width: 100%;

    margin: 50px 0px 0px 0px;
  }

  .video-section-container {
    display: flex;
    flex-direction: column;
    z-index: 29;
  }
}
@media screen and (max-width: $md-break) {
  .video-content {
    top: 40%;
    left: 12%;
    color: #fff;
    margin-top: -400px;

    .video-image {
      width: 100%;
      height: 100%;
      position: relative;
    }

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 40px;
      color: #464646;
      padding: 40px 0px 0px 0px;
      margin-left: 65px;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      max-width: 340px;
      color: #464646;
      margin-bottom: 0px;
      padding: 10px 0px 0px 0px;
      margin-left: 65px;
    }
  }

  .video-section {
    .video-section-div1 {
      width: 100%;
      height: 151px;
      margin: 0px 0px 0px -80px;

      .video-section-div1-btn {
        color: #b3e1c1;
        background: #6cc788;
        margin: 10px 0px 0px 0px;
        margin-left: 65px;
      }
    }
    .video-section-div2 {
      width: 100%;
      margin: 150px 0px 0px 0px;
    }
  }
}

.z-in-9 {
  z-index: 99;
}
.video-content {
  top: 8%;
  left: 9%;
  color: #fff;
  // margin-top: -320px;

  .video-image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 40px;
    color: #464646;
    padding: 40px 0px 0px 0px;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    max-width: 340px;
    color: #464646;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .custom-button .read-more {
    color: #b3e1c1;
    background: #6cc788;
    margin: 10px 0px 0px 0px;
  }
}
@media screen and (max-width: $md-break) {
  .video-content {
    top: 15px;
    left: 30px;
  }
}

.businesses-section {
  padding: 100px 0 50px;

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 55px;
    color: #464646;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: #464646;
  }
}

@media screen and (max-width: $md-break) {
  .businesses-section-image {
    padding-top: 350px;
    background: #fff;
    // background-image: url(../../Assets/Images/Group42.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.blog-section {
  padding: 0 0 10rem;
  width: 100%;
  background: #fff;
  // background-image: url(../../Assets/Images/blogbgImage.png);
  background-repeat: no-repeat;

  .blog-box {
    box-shadow: -1px 13px 20px #f0f2f4;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background: #050909;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 9999;
    &:hover {
      box-shadow: -1px 13px 20px #f0f2f4;
      &:after {
        content: "";
        background: #6cc788;
        opacity: 0.88;
        width: 104%;
        height: 110%;
        position: absolute;
        left: -15px;
        top: -15px;
      }
    }
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 45px;
    color: #464646;
    margin-bottom: 30px;
  }
  .subtitle.text-center {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    max-width: 537px;
    margin: auto;
    margin-bottom: 60px;
  }
  lable {
    font-family: Montserrat;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 37px;
    color: #fff;
  }
  .blog-content-box {
    max-width: 435px;
    margin: auto;
    position: relative;
    z-index: 9;
  }
  .blog-content-box h1 {
    text-shadow: rgb(0, 0, 0) 1.5px 2.5px;
  }

  .blog-content-box label {
    color: #fb9864;
  }

  .h-box-1 {
    min-height: 220px;
  }
  .h-box-2 {
    min-height: 320px;
  }
}
@media screen and (max-width: $md-break) {
  .blog-section-image {
    // margin-right: 1000px;
    background: #fff;
    // background-image: url(../../Assets/Images/Path2.png);
    background-repeat: no-repeat;
    background-position: -17% -4%;
    // background-size: cover;
  }
  @media screen and (max-width: 320px) {
    .blog-section-image {
      background-position: -67% -9%;
    }
  }
}

@media screen and (max-width: $md-break) {
  .blog-section {
    padding: 60px 0;
  }
  .businesses-section {
    padding: 34px 0 0;
  }
  .landing-page .landing-Top {
    height: unset;
    display: flex;
    flex-direction: column-reverse;
    .slider-img {
      display: none;
    }
  }
  .step-section-outer {
    margin-top: 100px;
  }
}
.auth-modal {
  padding-top: 125px;
  max-width: 600px;
  margin: auto;
}
.z-index-9 {
  z-index: 99;
}
@media screen and (max-width: $md-break) {
  .left-path::after {
    display: none;
  }
  .landing-page .form-wrapper {
    padding: 0px 0px 0px 0px;
  }
  .step-section-outer {
    padding-top: 0;
  }
  .title,
  .header {
    font-size: 20px !important;
    //  margin-bottom: 30px;
    padding-top: 30px;
    line-height: 28px !important;
    padding-top: 30px;
  }

  .instructions {
    font-size: 12px !important;
    line-height: 20px;
    margin-bottom: 1rem !important;
  }

  p {
    font-size: 13px !important;
  }
  ol li {
    font-size: 13px !important;
  }
  h4 {
    font-weight: 600 !important;
    font-size: 16px !important;
  }

  h1.blog-title {
    font-size: 28px;
  }
  .video-content h3 {
    font-size: 32px;
    line-height: 36px;
  }
  img.count-image {
    max-height: 100px;
  }
  .step-section h3 {
    margin-top: 60px;
  }
}

.custom-button.read-more {
  color: #6cc788;
  background: #fff;
}

button.play-btn {
  position: absolute;
  bottom: 60px;
  right: 60px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 72px;
  padding: 0;
  background: #fff;
  color: #6cc788;
  i {
    margin-top: -2px;
    margin-right: -1px;
  }
}
.product-card .card-body .product-info {
  margin: 7px auto;
}

.program {
  background: #fff;
  display: flex;
  flex-direction: row;
  .program-container {
    // margin-top: -6.1%;
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 55px;
      color: #464646;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      color: #464646;
    }
  }
}

.program-image {
  margin-top: 99px;
  padding: 5px;
  margin-left: 5%;
  width: 90%;
  height: auto;
}
@media screen and (max-width: $md-break) {
  .program {
    background: #fff;

    .program-container {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 55px;
      color: #464646;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      color: #464646;
    }
  }

  .program-image {
    margin-top: 60px;
    padding: 5px;
    margin-left: 5%;
    width: 90%;
    height: auto;
  }
}
