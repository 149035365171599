@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.modalReset{
    .modal-head{
        margin: auto;
    }
    .content .model {
        .modal-content{
          width: 450px;
        }
    }
    .modal-footer{
        .ant-row{
            .btn-cancel{
                background-color: white;
                color: black;
                border: 1px solid black;
            }
        }
    }
}