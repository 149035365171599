.content  {
    position: fixed;
    z-index: 1000 !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0px;
    background-color:rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .cross{
        position: relative !important;
        margin-bottom: 10px;
        min-height: 20px !important;
        min-width: 20px !important;
        height: 20px !important;
        width: 20px !important;
    }
    .model { 
        position: relative;      
        min-height: 50px;
        min-width: 50px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 12px;
        .modal-dialog {
            margin: 0px;
        }
    .model-Content {
        position: relative;
    }
}
}