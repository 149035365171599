@import "../../../Themes/theme";
@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.navbar {
  padding: 18px 114px ;
  min-height: $header-height;
  z-index: 1000;
  position: absolute;
  width: 100%;
  .logo-link {
    padding: 0px;

    .navbar-brand {
      padding: 0px;
      margin: 0px;

      .name-title {
        font-weight: bold;
        height: 100%;
        font-size: $title-font;
      }
    }
  }

  .nav-link-wrapper {
    .nav-link {
      padding: 0px;
    }

    .normal-links {
      .nav-link {
        padding-right: $bigger-gap;
      }

      .last-link {
        border-right: 1px solid $black;
      }
    }

    .login-link {
      color: $black !important;
      font-size: $big-font;
      min-width: $button-width;
      text-align: center;
    }

    .profile-link {
      color: $black !important;
      padding: 0px;
      padding-left: $bigger-gap;
      padding-right: $bigger-gap;
      text-align: center;

      span {
        font-size: $big-font;
        line-height: $button-height;
      }
    }

    .dropdown {
      .dropdown-menu {
        padding: 0px;
        .dropdown-item:active {
          background-color: $primary-green;
        }
      }
    }
  }
}

.navbar.light-navbar {
  background-color: $primary-green !important;
  .name-title,
  .login-link,
  .profile-link {
    color: $white !important;

  }

  .last-link {
    border-right: 1px solid $white !important;
  }

  .signup-button {
    color: $primary-green !important;
    background-color: $white !important;
  }

}
.fixed-top{
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    z-index: 999999 !important;
}
@media screen and (min-width: $md-break) and (max-width: $xl-break) {
  .navbar {
    padding: 1.5rem $hh-padding/2;
    .nav-link-wrapper {
      .normal-links {
        .nav-link {
          padding-right: 30px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: $base-gap $double-gap;
    // min-height: 30px;

    .nav-link-wrapper {
      .normal-links {
        .nav-link {
          padding: 0px;
          height: $button-height;
          line-height: $button-height;
        }

        .last-link {
          border-right: none !important;
        }
      }

      .login-link {
        height: $button-height;
        line-height: $button-height;
      }

      .profile-link {
        padding: 0px;
      }
    }
  }

  .navbar.light-navbar {
    .navbar-toggler {
      background-color: $white;
    }
  }
}


nav.navbar {
//Changed for chat header fixes
  position: relative;
  width: 100%;
  top: -1px;
}

nav.navbar.fixed-top{
  position: fixed;
   transition: all 0.5s ease;
}
.step-section-outer::after{

}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
