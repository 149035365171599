.other-user-profile {
    background-color: #F8F8F8;
    height: 420px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .other-user-profile-basic-Information {
        width: 80%;
        margin: 10px auto;
        .other-user-profile-basic-Information-value {
            color: black;
            font-weight: 600;
        }
    }
    p{
        margin: 5%;
    }
}

@media screen and (max-width: 40rem){
    .other-user-profile {
    height: 100%;
}
}


@media screen and (min-width: 40rem)
and (max-width: 1020px)
and (max-height: 1030px){
    .other-user-profile {
        height: 370px;
    }
}

