.StyleCustmAddres{
    border-radius: 5px;
    width: 100%;
    background: #f1f1f1;
    padding: 12px 15px;
    margin-bottom: 1rem;
    .CustomAddrsNames{
        margin-left: 2rem;
        ul{
            list-style-type: none;
            padding-left: unset;
            li{
                h5{
                    font-size: 17px;
                    font-weight: 500;
                    color: #03223a;
                    text-transform: capitalize;
                    font-style: unset;
                }
                h6{
                    text-transform: capitalize;
                    font-size: 14px;
                    color: #808080;
                    font-weight: 400;
                    font-style: unset;
                }
            }
        }
       
    }
    .ant-dropdown  {
        .ant-dropdown-menu{
            .ant-dropdown-menu-item{
                a:hover{
                        text-decoration: none !important;
                        background: none !important;
                        
                }
            }
        }
    }
   
 
    .ant-dropdown-link{
        svg{
            float:right;
            font-size: 22px;
        }
    }
    .ant-radio-checked .ant-radio-input{background-color: #66d687 !important;}
    .ant-radio{
        
        .ant-radio-inner{
            border-color: #66d687;

        }
    }
}

/* Responsive css */
@media screen and (max-width: 768px) {
    .StyleCustmAddres{
        width: 100%;
    }
  }