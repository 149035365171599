@import "../../../Themes/sizes";
@import "../../../Themes/colors";
.contact-pg{
 
position: absolute;
z-index: 99;    
.Business-Container{
    z-index: 1;
    position: relative;
    .app-info {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-bottom:6%;
        .title {
          margin: 0px;
          font-size: 60px;
          font-weight: 700;
          text-align: center;
        }
        .tagline {
          font-size: $normal-font;
          color: black;
          text-align: center;
        }
        .splitter {
            display: flex;
            .form-wrapper-business {
                width: 40%;
                padding: $double-gap $big-gap;
                background-color: $primary-green;
                margin: 0 auto;
            }
            .or-text {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                .line {
                    width: 2px;
                    height: 30%;
                    background-color: black;
                }
                .text-or {
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 20px;

                }
            }
            .submit-button-bu {
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                
            }
        }
    }
  
}
}

@media screen and (max-width: $md-break) {
    .Business-Container{
        .app-info {

            .splitter {
                display: flex;
                flex-direction: column;
                .form-wrapper-business {
                    width: 100% !important; 
                    padding: $double-gap $big-gap;
                    background-color: $primary-green;
                }
                .or-text {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .line {
                        height: 1px;
                        width: 30%;
                        background-color: black;
                    }
                    .text-or {
                        text-align: center;
                        margin-right: 30px;
                        margin-left: 30px;
    
                    }
                }
                .submit-button-bu {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                }
                .submit-button-bu{
                    .custom-button {
                            line-height: 16px;
                            font-weight: 600;
                            font-size: 13px;
                    } 
                }
               
            }
        }
      
    }
}