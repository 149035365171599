@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";
#our-services {
  .services-container {
    padding-left: $p-header-padding;
    width: 100%;
    height: 100%;
    .name-wrapper {
      position: relative;
      float: left;
      width: 20%;
      h2 {
        float: right;
        height: 100%;
        text-align: center;
        font-size: 68px;
        font-weight: 900;
        text-transform: uppercase;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        color: lightgrey;
        opacity: 0.4;
      }
    }
    .service-description {
      position: relative;
      float: right;
      padding: $bigger-gap;
      height: 100%;
      width: calc(100% - 20%);
      background-color: $primary-orange;
      .service {
        position: relative;
        float: left;
        width: 70%;
        color: white;
        &.right {
          float: right;
        }
        .title {
          margin: $base-gap auto;
          font-size: $title-font;
          font-weight: bold;
          color: white;
        }
        .description {
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  #our-services {
    .services-container {
      padding: 0px;
      .name-wrapper {
        display: none;
      }
      .service-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $double-gap;
        .service {
          &.right {
            align-self: flex-end;
          }
          .service-icon {
            height: $small-size !important;
          }
          .title {
            font-size: $big-font;
          }
        }
      }
    }
  }
}
