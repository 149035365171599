@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.billing-info-form {
  //padding: $double-gap 0px;
  .payment-title {
    margin-bottom: $double-gap;
  }
  .center-loader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }
  .ant-radio-group {
    width: 100%;
  .cards-row {
    padding-bottom: 15px;
    .card-Selection {
      height: 50px;
      border-radius: 10px;
      border-style: solid;
      border-width: 1px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .more-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        input[type="radio"] + label:before {
          background-clip: content-box;
          background-color: red;
        }
        .more-option-button {
          margin-left: 10px;
          
        }
        .drop-down {
          background-color: transparent;
          border: 0px;
        }
      }
      h5 {
        margin: 0;
      }
    }
  }
}
.ant-card-body {
  border-radius: 10px;
    border-style: solid;
    border-width: 0.5px;
  .field-row {
    display: flex;
    .field-col {
      .ant-form-item-control-wrapper {
        padding: 10px;
        border-radius: 10px;
        border: solid;
        border-width: 1px;
      }
      width: 100%;
      &.half-col {
        flex: 1;
      }
      &:not(:last-child) {
        margin-right: $double-gap;
      }
      .custom-input,
      .ant-select-selection {
        background-color: white !important;
        .ant-input {
          background-color: white !important;
        }
        .anticon svg {
          font-size: $biggest-font;
          color: lightgrey;
        }
        .anticon.ant-calendar-picker-icon {
          margin-top: -10px;
        }
      }
    }
  }
}
.save-button {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  .save-button-btn {
    min-width: 40%;
  }
}
}

@media screen and (max-width: 40rem) {
  .billing-info-form {
    .field-row {
      display: flex;
      flex-direction: column;
      .field-col {
        width: 100%;
        &.half-col {
          flex: 1;
          flex-direction: row;
        }
      }
    }
  }
}

