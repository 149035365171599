@import "../../Themes/sizes";
@import "../../Themes/colors";

.products-page {
        height: 100%;
        position: absolute;
        z-index: 99;
  .search-bar-wrapper {
    display: inline-block;
    
  }
  .product-filters {
    margin: $big-gap auto;
  }

  .result-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $base-gap;
    .result-count {
      color: black;
      font-weight: bold;
    }
    .sort-wrapper {
      
    
      .ant-select {

        margin-left: $base-gap;
        min-width: 150px;
        font-weight: bold;

      

      }
    }
  
  }
  .product-grid {
    display: grid;
    grid-gap: $big-gap;
    // grid-template-rows: repeat(0, minmax(75px, auto));
    grid-template-columns: repeat(5, 1fr);
    
  }
  .pagination-loader {
    margin-top: $big-gap;
    text-align: center;
    .loading-icon svg {
      font-size: $title-font;
    }
  }
}

@media screen and (min-width: $md-break) and (max-width: $xl-break) {
  .products-page {
    .product-grid {
      grid-gap: $double-gap;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width:320px){
  .product-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

}

@media screen and (max-width: $md-break) {
  .products-page {
    .search-bar-wrapper {
      display: block;
    }
    .product-filters {
      margin: $base-gap auto;
    }
    .product-grid {
      grid-gap: $base-gap;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
