@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";

.inbox {
  height: auto;
  position: relative;
  .row {
    margin: 0;
  }
  // margin-top: 1.2vh;
  // padding-top: $medium-gap;
  .inbox-head {
    border-bottom: 1px solid rgba(0,0,0,.1);
    background-color: #f8f8f8;
    display: flex;
    padding: 10px;
    // margin-left: 15px;
    // padding-bottom: 5px;
    .avatar {
      height: 60px;
      width: 60px;
      border-radius: 30px;
    }
    .avatar-text {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      margin: 0;
      padding-top: 10px;
      text-align: center;
      background: #f5f5f5;
    }

    .profile-name {
      font-size: 16px;
      font-weight: 600;
      padding-left: 2vh;
      margin: 0;
      line-height: 22px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .time {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  // .chat-wrap{
  //     position: relative;
  // }
  .chat-box {
    scroll-behavior: auto;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 50vh;
    margin-bottom: 10vh;
    padding-bottom: 12px;
    // position: relative;
    .msgs {
      display: flex;
      width: 100%;
      // position: absolute;
      // bottom: 50;
      margin-bottom: 0px;

      height: fit-content;
      .req-accept-wrap {
        margin: auto;
        .req-accept {
          margin: 5px;
          font-size: 16px;
          line-height: 24px;
          padding: 8px 16px;
          background: #6cc788;
          color: $white;
          height: fit-content;
          border-radius: 28px;
        }
      }
      .wrap-recieved {
        width: 100%;
        padding: 20px;
        padding: 0px 20px;
        .recieved {
          background: #f8f8f8;
          // background: #6CC788;
          margin: 1vh;
          font-size: 16px;
          line-height: 24px;
          padding: 5px 5px 5px 20px;
          border-radius: 28px;
        }
      }
      .msg-time-left {
        time {
          font-size: 10px;
        }
        line-height: 15px;
        text-align: left;
        padding: 4px 0px 0px 50px;
      }
      .after_1 {
        content: "";
        bottom: 13px;
        position: relative;
        border: 0px solid;
        display: block;
        width: 16.5px;
        height: 11px;
        background-color: transparent;
        border-bottom-right-radius: 140%;
        box-shadow: 13px 0px 0px 0px #f8f8f8;
        margin-left: -7px;
        float: left;
      }
      .msg-time {
        time {
          font-size: 10px;
        }
        line-height: 15px;
        text-align: right;
        // padding: 0vh 26px 0px 0px;
        padding: 0vh 50px 0px 0px;
      }
      .after {
        content: "";
        bottom: 13px;
        position: relative;
        width: 18.5px;
        margin-right: -7px;
        height: 11px;
        background-color: transparent;
        border-bottom-left-radius: 200%;
        box-shadow: -12.5px 0px 0px 0px #6cc788;
        float: right;
      }
      .wrap-send {
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0px 20px;
        .send {
          margin: 5px;
          font-size: 16px;
          line-height: 24px;
          padding: 5px 5px 5px 10px;
          // width: fit-content;
          background: #6cc788;
          color: $white;
          height: auto;
          width: auto;
          border-radius: 28px;
        }
      }
    }
  }
  .msg-image {
    padding: 10px;
    width: 300px;
    height: 300px;
    border-radius: 20px;
  }

  .field-wrap {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    border: 1px solid $lightgrey;
    margin-right: 15px;
    bottom: 0;
    position: absolute;
    background: $white;
    display: flex;
    align-items: center;
    .form {
      width: 100%;
      margin: 0 10px;
    }
    .input-msg {
      //   margin-left: 30px;
      height: 50px;
      width: 100%;
      padding: 0 20px;
      border-width: 0;
      border-radius: 28px;
      background: #f8f8f8;
    }
    .attachFile-wrap {
      //   position: absolute;
      //   height: 48px;
      //   width: 55px;
      //   margin-left: 45px;
      .AttachFile {
        // margin-top: 14px;
        // margin-left: 15px;
        height: 20px;
        width: 22px;
      }
    }

    .SentButton {
      height: 45px;
      width: 45px;
      //   margin-left: -34px;
      //   margin-right: 34px;
      border-radius: 26px;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .inbox {
    .chat-box {
      padding-bottom: 10px;
    }
  }

  _::-webkit-full-page-media,
  _:future,
  :root .safari_only,
  .inbox .chat-box {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 40rem) {
  .inbox {
    .field-wrap {
      position: absolute;
      bottom: 0;
      // margin-bottom: 30px;
      .SentButton {
        height: 45px;
        width: 45px;
      }
    }
  }
}

@media screen and (min-width: 40rem) and (max-width: 768px) {
  .inbox {
    .chat-box {
      .msgs {
        margin-bottom: 50px;
      }
    }
    .field-wrap {
      bottom: 0;
      // margin-bottom: 30px;
      .SentButton {
        height: 48px;
        width: 48px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1020px) and (max-height: 1000px) {
  .inbox {
    .chat-box {
      height: 60vh;
    }
    .field-wrap {
      bottom: 0;
      // margin-bottom: 30px;
      .SentButton {
        height: 48px;
        width: 48px;
      }
    }
  }
}
