@import "../../Themes/sizes";
@import "../../Themes/colors";

.chat-page{
    height: 100vh;
    .page{
        min-height: 90vh;
    }
.chat{
    margin: 0%;
    height: 90vh;
    display: flex;
    flex-direction: column;
   .chat-container{
        width: 100%;
        //padding-top: 1px;
        // padding-top: 0px;
        padding-bottom: 0px; 
        margin-bottom: 10vh;
        .ant-spin-nested-loading {
            padding-top: $double-gap;
            padding-bottom: $double-gap;
        }
        .ant-tabs-bar{
            width: fit-content;
        .ant-tabs-nav{
            .ant-tabs-tab:hover{
                color: $primary-green;
            }
            .ant-tabs-tab-active {
                color: $primary-green;
                border-bottom-color: $primary-green;
            }
            .ant-tabs-ink-bar {
                background-color: $primary-green;
                min-width: 88px;
            }
        }
    }
    }
    .heading-Buttons{
        width: 100%;
    }

}
.footer-bottom {
    background: white;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    padding: 0px 20px;
    bottom: 0%;
    left: 0%;
    height: 40px;
    border-top: 1px solid $lightgrey;
    p {
        // width: 60%;
        // margin: auto;
        text-align: center;
    }
  } 
}




@media screen and (max-width: 40rem){
    .chat-page{
        .chat{
            .chat-container{
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .heading-Buttons {
                h2{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 1rem;
                }
            }
            .heading-Buttons {
                display: block;
                position: absolute;
            }
          
        }
        .footer-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            width: 100%;
            padding: 0px 10px;
            bottom: 0%;
            left: 0%;
            height: 10vh;
            border-top: 0.5px solid $lightgrey;
            p {
              margin: 0px;
              text-align: center;
            }
          } 
    }
    }

@media only screen 
and (min-width : 40rem) 
and (max-width : $md-break)  {
    .chat-page{
    .chat{
        .chat-container{
            padding-top: 0px;
            padding-bottom: 0px;
            .ant-tabs-nav{
                .ant-tabs-tab-active {
                    font-size: 1.2rem;
                }
                .ant-tabs-tab:last-child{
                    font-size: 1.2rem;
                }
            } 
        }
        .heading-Buttons {
            h2{
                font-size: 2.5rem;
            }

            p{
                font-size: 1.5rem;
            }
        }
        .heading-Buttons {
            display: block;
            position: absolute;
        }
        
    }
    .footer-bottom {
        p{
            font-size: 1.2rem;
        }
    }
}
}

@media only screen 
and (min-width : $md-break) 
and (max-width : 900px)  {
    .chat-page{
    .chat{
        .chat-container{
            padding-top: 0px;
            padding-bottom: 0px;
            .ant-tabs-nav{
                .ant-tabs-tab-active {
                    font-size: 1.5rem;
                }
                .ant-tabs-tab:last-child{
                    font-size: 1.5rem;
                }
            } 
        }
        .heading-Buttons {
            h2{
                font-size: 2.5rem;
            }

            p{
                font-size: 1.5rem;
            }
        }
        .heading-Buttons {
            display: block;
            position: absolute;
        }
        
    }
    .footer-bottom {
        p{
            font-size: 1.5rem;
        }
    }
}
}

@media only screen 
and (min-width : 900px) 
and (max-width : 1024px)  {
    .chat-page{
    .chat{
        .chat-container{
            padding-top: 0px;
            padding-bottom: 0px;
            .ant-tabs-nav{
                .ant-tabs-tab-active {
                    font-size: 1.5rem;
                }
                .ant-tabs-tab:last-child{
                    font-size: 1.5rem;
                }
            } 
        }
        .heading-Buttons {
            h2{
                font-size: 2.5rem;
            }

            p{
                font-size: 1.5rem;
            }
        }

       
    }
    .footer-bottom {
        p{
            font-size: 1.5rem;
        }
    }
}
 }
  