.carousel-button-group {
  position: absolute;
  bottom: -90px;
  display: flex;
  width: 100%;
}

.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child {
  background: #6cc788;
  height: 48px;
  width: 48px;
  border: 0;
  display: flex;
  align-items: center;
  // color: #fff;
  border-radius: 100%;
  margin-right: 15px;
  padding: 0;
  justify-content: center;
}
label.disable.ant-radio-button-wrapper {
  // background: #fff;
  box-shadow: -1px -13px 20px #f0f2f4;
  color: #8aa1ac;
}

label.disable.ant-radio-button-wrapper:hover span {
  color: #787f82;
}
.ant-radio-button-wrapper:hover span {
  color: #787f82;
}
.ant-radio-button-wrapper:last-child {
  padding: 0px 20px;
  background-color: #6cc788;
  // color: #fff;
  font-size: 16px;
  min-width: 130px;
  min-height: 40px;
  line-height: 30px;
  border-radius: 4px;
  border: none;
  outline: none;
  margin-left: 31%;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.ant-radio-button-wrapper span {
  display: flex;
  align-items: center;
  color: #fff;
}
.ant-radio-button-wrapper span i {
  font-size: 32px;
}
.carousel-button-group {
  label:hover > span {
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .ant-radio-button-wrapper:last-child {
    margin-left: 0px;
  }
  .carousel-button-group {
    position: absolute;
    bottom: -77px;
  }
}
