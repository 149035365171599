.products-container{
    // padding-left: 10px;
    .productsHorizontal-container {
        // height: 370px;
        width: 100%;
        scroll-behavior: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        float: left;
        flex-direction: row;
        .productsHorizontal-Products {
            flex: 0 0 30%;
            width: 60px;
            margin-right: 22px;
        }
    }
    .WidthPofileProducts{
        height: unset !important;
    }
    .productsHorizontal-container::-webkit-scrollbar{
        display: none;
    }
    .add-title{
        font-size: 28px;
        line-height: 39px;
        font-weight: 400;
    }

    .arrow{
        position: relative;
        height: 20px;
        width: 10px;
        margin-top: 25%;
        float: right;
        
    }
    .reviews{
        text-align: center;
        // height: 500px;
        .noRev-img{
            height: 239px;
            width: 318px;
            margin-top: 50px;
        }
        .NoRev{
            font-size: 22px;
            line-height: 32px;
            word-spacing: 0.2px;
            color: #464646;
            font-weight: 400;
            margin-top: 20px;
        }
        .noRev-desc{
            font-size: 16px;
            line-height: 28px;
            word-spacing: 0.2px;
            color: #464646;
        }
    }
}


@media screen and (max-width: 40rem){
    .products-container{
        margin-top: 30px;
        // height: 800px;
        .productsHorizontal-container{
              height: 300px;
             .productsHorizontal-Products{
                flex: 0 0 45%;
            }
        }
        .add-title{
            margin-top: 50px;
        }
        .arrow{
            margin-top: 45%;
        }
        .reviews{
            .noRev-img{
                height: 139px;
                width: 218px;
            }
        }
    }
}


@media screen and (min-width: 40rem)
and (max-width: 1020px)
and (max-height: 1030px){
    .products-container{
        margin-top: 30px;
        .productsHorizontal-container{
            height: 320px;
             .productsHorizontal-Products{
                flex: 0 0 45%;
                // margin-right: 50px;
                // margin-left: 50px;
            }
        }
        .arrow{
            margin-top: 45%;
        }
    }
}

