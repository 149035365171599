@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.custom-select {
  border: none;
  height: $input-height;
  text-overflow: hidden;
  .ant-select-selection {
    margin: 0px;
    padding: $small-gap $base-gap;
    height: $input-height;
    line-height: $input-height - 2 * $small-gap;
    font-size: $big-font;
    color: black;
    border-radius: 0px;
    background-color: #f5f5f6;
    box-shadow: none;
    .ant-select-selection__rendered {
      margin: 0px;
    }
  }
}
