@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.open-msg {
  height: auto;
  .row-eq-height {
    display: flex;
    // margin-right: 15px;
    // margin-left: 15px
    .msgProductDetails {
      min-width: 300px;
    }
  }
  .second-nav {
    padding: 0px 20px;
    width: 100%;
    border-top: 1px solid $lightgrey;
    border-bottom: 1px solid $lightgrey;
    min-height: 5vh;
    .link-center {
      margin: auto;
    }
    .backLink {
      cursor: pointer;
    }
    p {
      margin: auto;
      a {
        color: #8c8c8c;
        font-size: 14px;
      }
    }
  }
  .msgProductDetails {
    padding-right: 0;
  }
  .inboxDetails {
    padding: 0;
  }
}

@media screen and (max-width: $md-break) {
  .open-msg {
    height: auto;
    .row-eq-height {
      display: block;
    }
  }
}
