@import "../../../Themes/colors";
@import "../../../Themes/sizes";
.signup-page {
  form {
    .signup-button-wrapper {
      text-align: center;
      .signup-button {
        width: 100%;
      }
    }
  }

  .terms{
    margin-top: 2%;
    text-align: center;    
    font-size: 0.9rem;
    .link-css{
      color: $primary-green;
      cursor: pointer;
    }
  }
}
