@import "../../Themes/colors";
@import "../../Themes/sizes";

.newPost {
  margin: 0;
  padding: 0%;
  top: 510;
  bottom: 0%;
  left: 0%;
  right: 0%;
  min-height: 500px;
  .row {
    margin: 0;
  }

  .spinner {
    margin-left: 47%;
    margin-top: 200px;
  }
  .newPost-pg {
    min-height: 0;
    margin: 0%;
    // .page-container {
    //     padding: 20px $hh-padding 0px;

    //   }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .new-section {
    padding: 0px 114px;
    width: 100%;
    margin-top: 70px;
    .Head-section {
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      h1 {
        font-size: 32px;
        font-weight: 14px;
        line-height: 39px;
      }
    }
    .top-main-section {
      margin-top: 30px;
      border-bottom: 1px solid #d8d8d8;
      .top-left {
        margin-bottom: 30px;
        h2 {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 20px;
        }
        p {
          line-height: 23px;
          padding: 10px;
        }
      }
      .top-right {
        margin-bottom: 30px;
        display: grid;
        grid-gap: $big-gap;
        grid-template-columns: repeat(3, 1fr);
        .main-img-div {
          position: relative;
          .del-img {
            background-color: white;
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            margin: 5px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .wrap-img-cover {
            background-color: #f9f9f9;
            border: 1px dashed $primary-green;
            border-radius: 5%;
            height: 150px;
            width: 150px;
            // background-image: url('../../Assets/Images/addimg.png');
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            overflow: hidden;
            .upload-button {
              background-repeat: no-repeat;
              background-position: center;
              overflow: hidden;
              height: 100%;
              width: 100%;
            }
            .add-img {
              //   padding: 5px;
              // background-color: #f9f9f9;
              // border: 1px dashed $primary-green;
              // border-radius: 5%;
              width: fit-content;
              // min-height: 150px;
              // max-width: 150px;
              width: 100%;
              height: 100%;
              max-height: 150px;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 40rem) and (min-width: 400px) {
//   .newPost {
//     .del-img {
//       position: absolute;
//       max-width: 20px;
//       max-height: 20px;
//       top: 0;
//       right: 30px !important;
//     }
//   }
// }

@media screen and (max-width: 40rem) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 0;
    // }
    .new-section {
      padding: unset;
      .top-main-section {
        .top-right {
          display: block;
          grid-gap: $medium-gap;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (min-width: 40rem) and (max-width: 48rem) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 0;
    // }
    .new-section {
      padding: 0px 30px;

      .top-main-section {
        .top-right {
          display: block;
          grid-gap: $small-gap;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 0;
    // }
    .new-section {
      padding: 0px 65px;
      .top-main-section {
        .top-right {
          display: grid;
          grid-gap: $small-gap;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media only screen and (min-width: 940px) and (max-width: 1024px) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 0;
    // }
    .new-section {
      padding: 0px 65px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 0;
    // }
    .new-section {
      padding: 0px 65px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 80px !important;
    // }
    .new-section {
      padding: 0px 65px;
    }
  }
}

@media only screen and (min-width: 1400px) and (min-height: 850px) {
  .newPost {
    // .del-img {
    //   position: absolute;
    //   max-width: 20px;
    //   max-height: 20px;
    //   top: 0;
    //   right: 30px !important;
    // }
  }
}
