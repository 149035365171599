@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.page {
  position: absolute;
  z-index: 9;
  width: 100%;
  min-width: 300px;
  min-height: 50vh;

  .user-profile {
    background-color: $primary-green;
  }
  .header-information {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      
    
  }

  .header-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    // background-image: url("../../../Assets/Images/background.jpg");
    background-image: url("../../../Assets/Images/home.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;

    .landing-Image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: none;
    }

    .section-container {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      // align-items: center;
      align-items: flex-end;
      // border: 1px solid $primary-green;
    }
  }


  .page-container {
    width: 100%;
    padding: $hh-padding;
    padding: 0px 114px 0px;
  }

  &.no-padding {
    .page-container {
      padding: 0px;
    }
  }
  &.no-v-padding {
    .page-container {
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }
  &.no-h-padding {
    .page-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (min-width: $md-break) and (max-width: $xl-break) {
  .page {
    .page-container {
      padding: $hh-padding/2;
    }
  }
}

@media screen and (max-width: $md-break) {
  .page {
    .header-section {
      height: auto;
      background-image: none;
      .landing-Image {
        display: block;
      }
      .section-container {
        padding: $big-gap;

      }
    }

    .page-container {
      padding: $double-gap;
    }
  }
}
