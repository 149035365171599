.custom-modal-body {
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.304);
  display: flex;
  justify-content: center;
  align-items: center;
  .basic-modal {
    background-color: white;
    padding: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.209);
    border-radius: 5px;
  }
}
