@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: rgba($primary-green, 0.55) !important;
    color: $white;
  }
  .Selectable .DayPicker-Day {
    
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    color: $primary-green;
    
    border-radius: 100% !important;
  }
  .Selectable .DayPicker-Day--end {
    color: $primary-green;
      border-radius: 100% !important;
      
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $primary-green;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $primary-green;
  }
  .ant-slider-handle {
    background-color: $white;
    border : none;
    box-shadow: 0px 2px 8px black !important;
  }
  .ant-tooltip-inner{
    background-color : $primary-green
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    border-top-color :  $primary-green;
  }
  
  .Slider-Container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    
    .Slider-children {  
      margin: 0 20px 0 20px;
      display: flex;
      flex: 1 0;
      flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .slider {
      flex: 1;
      width: 50px;
    }
    p {
      margin: 0;
    }
    }
  }
  .ant-slider-track {
    background-color : $primary-green;
  }
  
  .ant-slider-handle.ant-tooltip-open {
    border-color : transparent;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .5);
  }
