@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";
#features {
  .features-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px $p-header-padding;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 40%;
    .content-wrapper {
      display: flex;
      flex-direction: column;
      top: 0px;
      left: 0px;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-position: right center;
      background-size: 50%;
      background-repeat: no-repeat;
      z-index: 99;
      .feature-content {
        width: 50%;
        .description {
          p {
            text-transform: uppercase;
          }
        }
        .user-links-wrapper {
          margin-top: $bigger-gap;
          .user-links {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
          p {
            margin-top: $double-gap;
            text-align: center;
            color: $primary-green;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  #features {
    .features-container {
      padding: $base-gap;
      background-size: 90%;
      .content-wrapper {
        background-size: 90%;
        justify-content: flex-start;
        background-position: bottom right;
        .feature-content {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          margin-top: $bigger-gap;
          width: 100%;
        }
      }
    }
  }
}
