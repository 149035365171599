@import "../../../Themes/colors";
@import "../../../Themes/sizes";
.modal {
  padding: 0px;
}
.auth-modal {
  display: flex;
  padding: $big-gap $base-gap;
  width: calc(100% - 2 );
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  .modal-content {
    padding: 0px;
    background-color: $white;
    .close-btn-wrapper {
      position: absolute;
      width: 100%;
      top: -40px;
      text-align: center;
      .close-btn {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
      }
    }
    .nav,
    .tab {
      padding: 0px;
      font-size: $big-font;
      font-weight: 600;
      line-height: $tab-height;
      border: none !important;
    }
    .tab {
      width: 50%;
      text-align: center;
      color: $darkgrey;
      background-color: $lightgrey;
    }
    .tab.active {
      color: $primary-green;
      background-color: $white;
    }
    .tab-content {
      padding: $double-gap $big-gap;
      h4 {
        margin: 0px;
        font-size: $heading-font;
      }
      p,
      input {
        font-size: $big-font;
      }
    }
    form {
      margin-top: $double-gap;
      // text-align: center;
      .nav-link {
        color: $primary-green;
      }
    }
  }
}
