@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.product-card {
  .product-cover {
    position: relative;
    cursor: pointer;
    height: 60%;
    // height: 196px;
    overflow: hidden;
    .favorite-button {
      cursor: pointer;
      position: absolute;
      top: $base-gap;
      right: $base-gap;
      z-index: 999;
    }
    .edit-icon {
      cursor: pointer;
      position: absolute;
      background: white;
      z-index: 999;
      top: 0;
      right: 0;
      padding: 10px;
      margin: 5px;
      border-radius: 50%;
    }
    .edit-icon img {
      height: 20px;
    }
    .Ship_butn {
      right: 46px;
    }
  }

  .card-body {
    cursor: pointer;
    .product-info {
      margin: 13px auto;

      .product-title {
        font-size: $bigger-font;
        font-weight: bold;
        line-height: $biggest-font;
        max-height: 2 * $biggest-font;
        text-transform: capitalize;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }

      .product-desc {
        margin: 0px;
        line-height: $big-font;
        max-height: $big-font;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .product-price {
      color: $primary-green;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $md-break) {
  .product-card {
    .product-cover {
      height: 160px;
    }
    .ant-card-body {
      padding: $base-gap;
    }
    .image-wrapper {
      .product-images {
        .slick-dots {
          button {
            width: $tiny-size;
            height: $tiny-size;
            border-radius: $tiny-size/2;
          }
        }
      }
    }

    .product-info {
      margin: $base-gap auto;

      .product-title {
        font-size: $normal-font;
      }

      .product-desc {
        font-size: $small-font;
      }
    }

    .product-price {
      font-size: $small-font;
    }
    .favorite-button {
      height: $small-size/1.2 !important;
    }
  }
}
