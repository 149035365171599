@import "../../Themes/sizes";
@import "../../Themes/colors";


.Blog{   
    margin: 0;
    padding: 0%;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    .row{
        margin: 0;
    }
    .blog-pg{
        min-height: 0;
        margin: 0%;
        .page-container {
            padding: 40px $hh-padding 0px;
            
          }
    }
    .second-nav{
        padding: 0px 114px; 
        width: 100%;
        p{
            a{
                color:#8C8C8C;
                font-size: 14px;
            }
            
        }

        .link-center{    
            flex: 1;
            text-align: center;
            margin-left: -50px;
            p{
            span{

                a{
                    color:#8C8C8C;
                    font-size: 14px;
                }
                a:hover{
                   text-decoration: none;
                }
            }
         }
        }
    }
    
    .Top-section{
        width: 100%;
        height: auto;
        background-image: url("../../Assets//Images/Rectangle.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; 
        .Top-content{
            padding-top: 150px;
           // padding-right: 140px;
            h1,h4, h6{
            color: white; 
            text-shadow: 1px 1px #000000;
            text-align: left;
            }         
        }
        .second-content{
            padding-bottom: 2rem;
            .kizz-img{
                height: 50px;
                width: 50px;
                float: left;
                border-radius: 25px;
            }

            p{
                padding-bottom: 50px;
            }   
        }
           
        
    }

    .center {
        padding-left: 26%; 
    }

    .Blog-content{
        margin-top: 40px;
        margin-bottom: 20px;
        h4{ 
            text-align: center;
            font-size: 24px;
            width: 100%;
            word-spacing: 0.9px;
            font-weight: bold;
            line-height: 42px;
            color: #464646;
        }
        p{
            display: flex;
            text-align: left;
            font-size: 18px;
            width: 100%;
            font-weight: 400;
            word-spacing: 1.1px;
            line-height: 42px;
            color: #464646;
        }
        .-img{
            height: 380px;
            width: 100%;
        }
    }
}


@media screen and (max-width: 40rem) {
    .Blog{
        .second-nav {
            padding: 0px 20px;
        }
        .blog-pg{
        min-height: 0;
        padding: 0% !important;
        margin: 0%;
        
        .page-container {
            padding: 0px;
            p{
                font-size: 12px;
                span{
                    font-size: 12px;
                }
            }
          }
    }
        .Top-section{
            width: 100%;
            height: auto;
            padding: 5vh;
            background-position: center; 
                .Top-content{
                    padding-top: 10px !important;
                    padding-left: 50px;
                    h1{
                            font-size: 1rem;
                        }  
                }
                    h5{
                        font-size: 1rem;
                    }
                    p{
                        font-size: 0.8rem;
                    }
            }
            .center {
                padding-left: 0%; 
            }

            .Blog-content{
                h4{
                    padding: 0 20px;
                    text-align: CENTER;
                    font-weight: 800;
                    font-size: 0.8REM;
                }
                p{
                    display: flex;
                    font-size: 12px;
                    padding: 0 20px;
                    line-height: 30px;
                    word-spacing: 0px;
                }
            }
        .-img{
            width: 100%;
            height: 210px !important;
        }
    }   
    .second-content{
        p{
            padding-bottom: 0px !important;
        }   
    }
}


@media screen 
and (min-width: 40rem) 
and (max-width: 48rem) {
    .Blog{
        .second-nav {
            padding: 0px 30px;;
        }
        .Top-section{ 
            height: auto;
            background-position: center; 
                .Top-content{
                    padding-top: 50px;
                    padding-left: 50px;
                    h1{
                            font-size: 2rem;
                        }  
                }
                h5{
                    font-size: 1.2rem;
                }
                p{
                    font-size: 1rem;
                }
            }
        }
}

@media screen 
and (min-width: 60rem) 
and (max-width : 768px) {
    .Blog{
        .Top-section{ 
            height: auto;
            background-position: center; 
                .Top-content{
                    padding-top: 100px;
                    padding-left: 50px;
                    h1{
                            font-size: 2rem;
                        }  
                }
                h5{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.2rem;
                }
            }
        }
        .center {
            padding-left: 0%; 
        }
    
}

@media screen 
and (min-width: 768px) 
and (max-width : 900px) {
    .Blog{
         
        .second-nav {
            padding: 0px 65px;
        }
        .blog-pg{
            .navbar .nav-link-wrapper {
                .normal-links {
                    .last-link {
                        border-right: 1px solid #000 !important;
                    }
                    .nav-link {
                        padding-right: 30px;
                    }
                }
                .profile-link span {
                    padding: 0px 15px;
                }
            }
        }
        .Top-section{ 
            height: auto;
            background-position: center; 
                .Top-content{
                    padding-top: 100px;
                    padding-left: 50px;
                          h1{
                            font-size: rem;
                        } 
                }
                h5{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.2rem;
                }
            }
        }
        .center {
            padding-left: 0%; 
        }
    
}

@media only screen 
and (min-width : 900px) 
and (max-width : 1024px)  { 
    .Blog{   
        .second-nav {
            padding: 0px 65px;
        }
        .blog-pg{
            min-height: 0;
            padding: 0% !important;
            margin: 0%;
            .page-container {
                padding: 20px $hh-padding 0px;
                p{
                    font-size: 1.5REM;
                    span{
                        font-size: 1.5REM;
                    }
                }
              }
        }
        .Top-section{
            width: 100%;
            height: auto;
            padding: 5vh;
            background-position: center; 
            .Top-content{
                display: flex;
                justify-content: center;
                padding-top: 50px;
                h1, h4, h6{
                color: white; 
                text-align: left;
                width: 100%;
                }
                
            }
            .second-content{
                .kizz-img{
                    height: 50px;
                    width: 50px;
                    float: left;
                }

                h5{
                    font-size: 2rem;
                    font-weight: 600;
                }
            }
               
            p{
                font-size: 1.5rem;
            }
            
        }
    
        .center {
            padding-left: 0%; 
        }
    
        .Blog-content{
            margin-top: 10px;
            margin-bottom: 20px;
            h4{
                padding: 0px;
                text-align: center;
                font-size: 2REM;
                font-weight: 550;
            }
            p{
                display: flex;
                font-size: 1.5rem;
                width: 100%;
            }
            .-img{
                height: 310px;
                width: 110%;
            }
        }
    }
}

 
@media only screen 
and (min-width : 1024px) 
and (max-width : 1200px)  { 
    .Blog{   
        .second-nav {
            padding: 0px 65px;
        }
    }
}