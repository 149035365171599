@import "../../../Themes/sizes";

.label-wrapper {
  .header {
    font-size: $title-font;
    font-weight: 700;
    color: #464646;
  }

  .header,
  .label-container {
    margin-bottom: 50px;
  }

  .footer {
    text-align: center;
  }
}

@media screen and (max-width: $sm-break) {
  .label-wrapper {
    .label-container {
      margin: $base-gap 0px;
    }
  }
}
