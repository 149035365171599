@import '../../../Themes/colors';
@import '../../../Themes/sizes';

.user-Detail-verification-detail {
    width: 100%;
    
    .user-Detail-verification-Model-PopUp {
        background-color: black;
        position: fixed;
        top: 0px;
        left: 00px;
        right: 0px;
        bottom: 0px;
        z-index: 99;
        padding: 30px;
        
        .cross-button {
            border: none;
            background: transparent;
            height: 40px;
            width: 40px;
            position: absolute;
            left: 0px;
            top: 0px;
            img {
                width: 100%;
                height: 60%;
            }
        }
        .pop-image {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            // background-color: blue;
            .camera {
                width: 100%;
                height: 100%;
            }
            .capture-button {
                position: relative;
                left: 45%;
                bottom: 40px;
            }
        }
        
    }
    .user-detail-verification-model {
        min-width: 300px;
        width: 20%;
        height: auto; 
        margin: auto;
        // margin-left:20%; 
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        

        .user-detail-verification-selfie-view {
            margin: 10px auto;
            height: 20px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .user-detail-selfie {
            width: 100%;
            height: 140px;
            background-image: url("../../../Assets/Placeholder/Images/TakeSelfie.png");
            background-size: 60%;
        }

        .user-detail-borderImagePosition {
            background-position: center;
            background-repeat: no-repeat;
            background-color: #F9F9F9;
            border-radius: 10px;
            border: 2px dashed $primary-green;
            overflow: hidden;
        } 
        .user-detail-govtDocument {
            width: 100%;
            height: 140px;
            background-size: 60%;
            background-image: url("../../../Assets/Placeholder/Images/takePicture.png");
            margin-top: 20px;
        }
        
        .user-detail-selfie-Taken {
            background-image: url("../../../Assets/Placeholder/Images/selfieTaken.png");
        }
        .user-detail-govt-Taken {
            background-image: url("../../../Assets/Placeholder/Images/govtTaken.png");
        }
        .uploadButton {
            margin-bottom: 10px;
        }
        
    }
}
@media screen and (max-width:320px){
    .user-Detail-verification-detail {
        .user-detail-verification-model {
          margin:0 auto;
          min-width: 245px;
        }
     }
}
@media screen and (max-width:320px){
    ._verificationTabs{
        .user-Detail-verification-detail {
            .user-detail-verification-model {
              margin-left: 0 !important;
              
            }
         }
    }
  
}
@media screen and (min-width:321px) and (max-width:375px){
    ._verificationTabs{
        .user-Detail-verification-detail {
            .user-detail-verification-model {
              margin-left: 0 !important;
              
            }
         }
    }
  
}

@media screen and (min-width:376px) and (max-width:411px){
    .user-Detail-verification-detail {
        .user-detail-verification-model {
            margin:0 auto;
            min-width: 327px;
        }
     }
}
@media screen and (min-width:321px) and (max-width:375px){
    .user-Detail-verification-detail {
        .user-detail-verification-model {
            margin:0 auto;
          min-width: 290px;
        }
     }
}

//  @media screen and (max-width: $sm-break) {
//     .user-Detail-verification-detail {
//         .user-detail-verification-model {
//           margin-left:0;
//           min-width: 245px;
//         }
//      }
// }

@media screen and (min-width: $sm-break) and (max-width: $md-break) {
    .user-Detail-verification-detail {
        .user-detail-verification-model {
            margin-left:6vw;
        }
    }
}
  
@media screen and (min-width: $md-break) and (max-width: 900px){
    .user-Detail-verification-detail {
        .user-detail-verification-model {
            margin-left:-2vw;
        }
    }
}