@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";

.inbox-product {
  // margin-top: 1.2vh;
  // padding-left: $bigger-gap;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  background-color: #f8f8f8;
  height: 100%;
  hr {
    margin: $double-gap auto;
  }
  .inbox-product-title {
    font-size: $biggest-font;
    padding: 0px 15px;
  }
  .product-view {
    display: flex;
    padding: $double-gap 0px;
    .productImages {
      // padding: 0px $double-gap ;
      height: 88px;
      min-width: 88px;
      width: auto;
    }
    .product-name {
      flex: 1;
      margin: 0px;
      font-size: $bigger-font;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px $double-gap;
    }
    .product-price {
      font-size: $big-font;
      font-weight: 600;
      padding-right: 48px;
    }
  }
  .more-btn-wrap {
    width: 100%;
    .more-btn {
      margin: 25px 30% 25px;
    }
  }
}

@media only screen and (max-width: 40rem) {
  .inbox-product {
    margin-top: 0;
    padding-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .product-view {
      padding: 1% !important;
      .bag {
        padding: 0px;
      }
      .product-name {
        font-size: 14px;
      }
      .product-price {
        font-size: 14px;
      }
      .location {
        display: none;
      }

      .condition {
        display: none;
      }
    }

    .more-btn-wrap {
      display: none;
    }
  }
}

// @media only screen and (min-width: 40rem)
// and (max-width : 768px) {
//   .inbox-product{
//     height: 100%;
//   }
// }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .inbox-product {
    height: 100%;
  }
}
