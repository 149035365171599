@import "../../Themes/colors";
@import "../../Themes/sizes";

.user-profile-page-container {
  padding-left: $hh-padding;
  padding-right: $hh-padding;
  min-height: 300px;

  .spin {
    text-align: center;
    margin-left: 50%;
    margin-top: 10%;
  }
  .No-product {
    text-align: center;
    margin-left: 20%;
    margin-top: 20%;
    font-size: 30px;
  }
  .reviews {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
  }
  .reviews-list {
    margin: 50px 0;
  }
}

@media screen and (max-width: 40rem) {
  .user-profile-page-container {
    padding-left: $medium-gap;
    padding-right: $medium-gap;
  }
}

@media screen and (min-width: 40rem) and (max-width: 1020px) and (max-height: 1030px) {
  .user-profile-page-container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .reviews {
    margin-top: 60px;
  }
}
