@import "../../Themes/sizes";
@import "../../Themes/colors";

.AboutUs{
    margin: 0;
    padding: 0%;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    .about-pg{
        min-height: 0;
        margin: 0%;
        .page-container {
            padding: 20px $hh-padding 0px;
          }
    }
    .about-container{
        height: 90% !important;
    }
}


@media screen and (max-width: 40rem) {
    .AboutUs{
        .about-pg{
            min-height: 0;
            padding: 0% !important;
            margin: 0%;
            .page-container {
                padding: 0px;
                p{
                    font-size: 12px;
                    span{
                        font-size: 12px;
                    }
                }
              }
        }
    }
}


@media screen 
and (min-width: 768px) 
and (max-width : 900px) { 
    .AboutUs{
        .section{
            height:80vh;
        }
        .about-pg{
            .navbar .nav-link-wrapper {
                .normal-links {
                    .last-link {
                        border-right: 1px solid #000 !important;
                    }
                    .nav-link {
                        padding-right: 30px;
                    }
                }
                .profile-link span {
                    padding: 0px 15px;
                }
            }
        }
    }
}


@media only screen 
and (min-width : 900px) 
and (max-width : 1024px)  { 
    .AboutUs{
        .section{
            height:50vh;
        }
        .about-pg{
            min-height: 0;
            padding: 0% !important;
            margin: 0%;
            .page-container {
                padding: 20px $hh-padding 0px;
                p{
                    font-size: 1.5REM;
                    span{
                        font-size: 1.5REM;
                    }
                }
              }
        }
    }
}


@media only screen 
and (min-width : 1024px)  { 
    .AboutUs{
        .section{
            height:50vh;
        }
    }
}