@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.messages {
  p {
    margin: 0;
  }
  .dropdown {
    button {
      background-color: #6bc788 !important;
      border-color: white !important;
    }
  }
  .msg-row {
    position: relative;
    bottom: 0;
    height: auto;
    width: 100%;
    border-bottom: 1px solid $lightgrey;
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: space-between;
    .dot-wrap {
      .dropdown-menu {
        min-width: 0rem;
        transform: translate3d(-5rem, 60px, 0px) !important;
        .dropdown-item {
          a {
            &::selection {
              color: $black;
              background: $white;
            }
          }
        }
      }
      .btn-primary {
        background-color: $white;
        border-color: $white;
      }
      .menuDots {
        // margin-top: 3vh;
        cursor: pointer;
      }
    }
    .col-left {
      min-width: 20%;
      display: flex;
      flex-direction: row;
      .profile {
        margin: 10px;
        height: 50px;
        width: 50px;
        .chat_badge {
          position: absolute;
          z-index: 2;
          top: 10px;
          font-size: 0.5rem;
          font-weight: 700;
          padding: 2px;
          color: white;
          left: 48px;
          border: 2px solid white;
          height: 17px;
          width: 17px;
          background-color: #ff9a8a;
          text-align: center;
          border-radius: 50%;
        }

        .profile-pic {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
        .profile-pic-text {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          padding-top: 10px;
          background: #f5f5f5;
          font-size: 1.8rem;
          text-align: center;
        }
      }

      .name {
        margin: auto 10px;
        font-size: 18px !important;
        line-height: 22px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .col-middle {
      display: block;
      width: 20%;
      .inbox-msg {
        font-size: 18px;
        margin: auto;
        cursor: pointer;
      }
      .product {
        font-size: 14px;
        margin: auto;
        cursor: pointer;
      }
    }
    .status {
      padding: 1%;
      .status-msg {
        font-size: 18px;
        margin: auto;
        color: #6cc788;
        font-weight: bold;
        cursor: pointer;
      }
      .status-cencel {
        font-size: 18px;
        margin: auto;
        color: #ff9a8a;
        font-weight: bold;
      }
      .price {
        font-size: 14px;
        cursor: pointer;
      }
    }
    .col-right {
      padding: 1%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .messages {
    height: auto;
    padding-bottom: 0;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only,
  .messages {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: $md-break) {
  .messages {
    .msg-row {
      .dot-wrap {
        .dropdown-menu {
          min-width: 0rem;
          transform: translate3d(-6rem, 25px, 0px) !important;
        }
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  .col-middle {
    display: none !important;
  }
}