@import "../../../Themes/colors";
@import "../../../Themes/sizes";
.search-bar {
  display: flex;
  background-color: $white;
  padding: $base-gap;
  border-radius: $small-radius;
  &.dark {
    background-color: rgba(150, 150, 150, 0.1);
  }
  .column {
    flex: 1;
    min-width: 200px;
    text-align: center;
    padding: 0px;

    margin: 0px;
    .location-feild{
      height: $button-height + $small-gap;
      border: none;
      background-color: transparent;
    }
    .ant-input-prefix {
      left: $small-gap;
    }
    .input-icon {
      svg {
        color: $primary-green;
        font-size: $small-size;
      }
    }
    .ant-input {
      padding-left: $bigger-gap;
      height: $button-height + $small-gap;
      color: $black;
      font-size: $big-font;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

@media screen and (max-width: $md-break) {
  .search-bar {
    .column {
      padding-bottom: 5px;
    }
    flex-direction: column;
    .location-feild{
      width: 90%;
    }
  }
}
