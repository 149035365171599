@import "../../Themes/colors";
@import "../../Themes/sizes";
.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-size: 10 * $biggest-font;
  }
  h4 {
    font-size: 2 * $biggest-font;
  }
}
