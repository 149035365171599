@import "../../Themes/sizes";
@import "../../Themes/colors";

.terms-page {
  position: absolute;
    height: 100%;
  .terms-page-container {
    padding-top: $bigger-gap;
    text-align: justify;
    position: relative;
    z-index: 2;
    .title {
      margin-bottom: $bigger-gap;
      font-weight: bold;
      text-align: center;
    }
    .instructions {
      text-align: center;
      font-weight: bold;
      font-size: $big-font;
     margin-bottom: 2rem;
    }
    h4{
      font-weight: 600;
    }

    p,
    ol li {
      font-weight: 500;
      font-size: $big-font;
    }
  }
}


