@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.preview-action-bar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0px;
    bottom: 0px;
    width: 100%;
    min-height: $header-height;
    padding: $base-gap $hh-padding;
    background-color: $white;
    
  .action-buttons {   
    text-align: right;
    width: 100%;
    .action-button {
      cursor: pointer;
      margin-left: $double-gap;
    }
  }

  .back-button {
    cursor: pointer;
    font-size: $big-font;
    border: none;
    outline: none;
    background-color: transparent;
    margin: 20px 0;
    .back-arrow {
      margin-right: $base-gap;
    }
  }
}


@media screen and (max-width: $sm-break) {
  .preview-action-bar {
    padding: $base-gap $double-gap;
    
    .back-button {
      margin: 20px 0;
      float: left;
      .back-arrow {
        margin-right: $base-gap;
      }
    }

  .action-buttons {   
    width: 100%;
    .action-button {
      float: right;
      margin: 15px 0px;
    }
  }
}
}
