@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.slider {
    margin: 40px 10px;
    width: 200px;
}

.ant-slider-handle {
  background-color: $white;
  border : none;
  box-shadow: 0px 2px 8px black !important;
}
.ant-tooltip-inner{
  background-color : $primary-green
}
.ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  border-top-color :  $primary-green;
}