@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";
#home {
  position: relative;
  width: 100%;
  .home-container {
    padding: 0px $p-header-padding;
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-position: top right;
    background-repeat: no-repeat;
    .app-info {
      position: relative;
      float: left;
      display: flex;
      width: 35%;
      min-width: 300px;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .title {
        margin: 0px;
        font-size: 60px;
        font-weight: 700;
      }
      .tagline {
        font-size: $normal-font;
        color: black;
      }
      .form-wrapper {
        padding: $double-gap $big-gap;
        background-color: $primary-green;
      }
    }
    .float-button {
      position: absolute;
      bottom: $bigger-gap;
      left: 50%;
      transform: translateX(-$normal-size/2);
      img {
        width: $normal-size;
        height: $normal-size;
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  #home {
    position: relative;
    .home-container {
      padding: $base-gap;
      background-size: 0px;
      .app-info {
        margin-top: $bigger-gap;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}
