@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.modelSizeClass  {
    width: 40%;
    background-color:rgba(0, 0, 0, 0) !important;
}

@media screen and (max-width: $md-break) {
    .modelSizeClass
    {    width:100%;
        padding: 10px;
}
}
