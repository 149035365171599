@import "../../../Themes/sizes";
@import "../../../Themes/colors";
#contact-form {
  position: relative;
  width: 100%;
  .contact-form {
    .ant-form-item {
      margin-bottom: 10px;
      .message{
        height: 15vh !important;
      }
      h2,
      p {
        margin: 0px;
        color: white;
      }
      h2 {
        font-weight: 600;
      }
      p {
        line-height: 20px;
        margin-bottom: $double-gap;
      }
      .ant-input {
        height: $input-height;
        border: none;
        border-radius: 0px !important;
      }
      textarea.ant-input {
        height: 2 * $input-height;
      }
      .ant-btn {
        margin-top: $double-gap;
        width: 100%;
        height: $input-height;
        color: white;
        border-radius: 0px !important;
        background-color: #333734;
        border-color: #333734;
      }
    }
  }
}
