@import "../Themes/colors";
@import "../Themes/sizes";
.border-facebook {
  border-color: $facebook !important;
}

.text-facebook {
  color: $facebook !important;
}

.border-google {
  border-color: $red !important;
}

.text-google {
  color: $red !important;
}
.ant-form-item-control {
  line-height: initial !important;
}
.ant-form-item-label {
  line-height: initial !important;
}
