@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.online-status-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: $small-gap;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  &.online {
    background-color: #6cc787f0;
  }
  &.offline {
    background-color: rgba(225, 0, 0, 0.8);
  }
  .status-message {
    margin-left: $base-gap;
  }
}
