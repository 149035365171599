@import "../Themes/sizes";

* {
  font-size: $normal-font;
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration-line: none;
  color: black;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  box-shadow: none !important;
}
