@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.postform{    
    margin: 0;
    padding: 0%;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    .row{
        margin: 0;
    }

        .ant-form-item{
            margin-bottom: 0;
        }

        .ant-form-explain{
            margin-bottom: 5px;
            margin-left: 21%; 
        }

        .ant-input-affix-wrapper {
            .ant-input:not(:last-child) {
                padding-right: 55px;
                background-color: #f5f5f6;
                border: 1px solid #DEE2E5;
            }
        }

        .mid-main-wrap{
            border-bottom: 1px solid #D8D8D8;
            padding-bottom: 20px;
        .mid-main-section{
            margin-top: 30px;
            h2{
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 20px;
            }
            .select-cat{
                .cat-custom{
                    width: 346px;
                    height: 30px;
                    background-color: #f5f5f6;
                    .ant-select-selection {
                        background-color: #f5f5f6;
                    }
                }
            }
            .select-con{
                .con-custom{
                    width: 346px;
                    height: 30px;
                    background-color: #f5f5f6;
                    margin-bottom: 10px;
                    .ant-select-selection {
                        background-color: #f5f5f6;
                    }
                }
            }
        }
        }
        .middle-wrap{
            border-bottom: 1px solid #D8D8D8;
            padding-bottom: 20px;
        .middle-section{
            .left-middle-section{
                h2{
                    font-size: 18px;
                    line-height: 22px;
                    margin-top: 20px;
                }
            }

            .right-middle-section{
                .productName-feild{
                    margin-top: 20px;
                    width: 346px;
                    height: 30px;
                    background-color: #f5f5f6;
                    border: 1px solid #DEE2E5;
                    border-radius: 5px;
                }
                .rate-fields{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    .hour-feild{
                        width: 165px;
                        height: 30px;
                        background-color: #f5f5f6;
                        border: 1px solid #DEE2E5;
                        border-radius: 5px;
                        margin-right: 20px;
                        text-align: right;
                    }
                    .week-feild{
                        width: 165px;
                        height: 30px;
                        background-color: #f5f5f6;
                        border: 1px solid #DEE2E5;
                        border-radius: 5px;
                        text-align: right;
                    }   
                    .ant-form-explain{
                        margin-bottom: 5px;
                        margin-left: 0%; 
                    }
                }
                .Desc-feild{
                    width: 346px;
                    background-color: #f5f5f6;
                    border: 1px solid #DEE2E5;
                    border-radius: 5px;    
                    margin-top: 20px;
                }
            }
        }
    }

    .bottom-wrap{
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 20px;
        .bottom-section{
            margin-top: 30px;
                h2{
                    font-size: 18px;
                    line-height: 22px;
                }
                .location-feild{
                    width: 346px;
                    height: 30px;
                    background-color: #f5f5f6;
                    border: 1px solid #DEE2E5;
                    border-radius: 5px;
                    margin-right: 10px;
                }
        }
    }
        .footer-section{
            margin: 30px 0px;
            .btn-wrap{
                width: 100%;
                text-align: right;
            .Preview-Btn{
                background-color: white;
                color: $primary-green;
                border: 1px solid #DEE2E5;
                margin: 10px !important;
            }
            .Post-Btn{
                margin: 10px;
            }
        }
        
      }
}


@media screen and (max-width: 40rem) {
    .postform{


        .ant-form-explain{
            margin-left: 0;
        }

             .mid-main-wrap{
                .mid-main-section{
                    .select-cat{
                        .cat-custom{
                            width: 100%;
                        }
                    }
                    .select-con{
                        .con-custom{
                            width: 100%;
                        }
                    }
                }
            }

            .middle-wrap{
            .middle-section{
                .right-middle-section{
                    .productName-feild{
                        width: 100%;
                        margin-top:5px;
                    }
                    .rate-fields{
                        flex-direction: row;
                        margin-top:5px;

                        .hour-feild{
                            width: 98%;
                        }
                        .week-feild{
                            width: 98%;
                        }   
                    }
                    .Desc-feild{
                        width: 100%;
                        margin-top:5px;

                    }
                }
            }
        }

        .bottom-section{
                .location-feild{
                    width: 89% !important;
                }
        }

        .footer-section{
            .btn-wrap{
                text-align: center;
        }
    }

        }
    
}


@media screen 
and (min-width: 40rem) 
and (max-width: 48rem) {
    .postform{


        .ant-form-explain{
            margin-left: 0%; 
        }

             .footer-section{
                .btn-wrap{
                    text-align: center;
            }
        }
            }   
        
}


@media screen 
and (min-width: 768px) 
and (max-width: 900px) {
    .postform{   

        .ant-form-explain{
            margin-left: 25%; 
        }
    }
}