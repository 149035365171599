// Screen break pixels
$sm-break: 576px;
$md-break: 768px;
$lg-break: 992px;
$xl-break: 1200px;
// Font Sizes
$smallest-font: 8px;
$smaller-font: 10px;
$small-font: 12px;
$normal-font: 14px;
$big-font: 16px;
$bigger-font: 18px;
$biggest-font: 20px;
$heading-font: 22px;
$title-font: 10px;
// Radius
$small-radius: 2px;
$normal-radius: 4px;
// Margin and Paddings
$small-gap: 5px;
$base-gap: 10px;
$medium-gap: 15px;
$double-gap: 20px;
$big-gap: 30px;
$bigger-gap: 40px;
$larger-gap: 80px;
// Logo/Image Dimensions
$tiny-size: 10px;
$small-size: 25px;
$normal-size: 50px;
$large-size: 100px;
$largest-size: 200px;
// Dimensions
$small-width: 20px;
$normal-width: 30px;
$large-width: 50px;
// UI sizes
$p-header-height: 110px;
$p-header-padding: 150px;
$header-height: 80px;
$hh-padding: 114px;
$hv-padding: 18px;
$tab-height: 45px;
$button-width: 130px;
$button-height: 40px;
$input-height: 40px;

@media screen and (max-width: $md-break) {
  $p-header-padding: 20px;
}
