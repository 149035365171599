@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.custom-input.ant-input {
  padding: $small-gap $base-gap;
  height: $input-height;
  line-height: $input-height - 2 * $small-gap;
  font-size: $big-font;
  color: black;
  border-radius: 0px;
  background-color: #f5f5f6;
}

.custom-input {
  .ant-input {
    padding: $small-gap $base-gap;
    height: $input-height;
    line-height: $input-height - 2 * $small-gap;
    font-size: $big-font;
    color: black;
    border-radius: 0px;
    background-color: #f5f5f6;
  }
}
