@import "../../../Themes/colors";
@import "../../../Themes/sizes";
footer {
  // color: $white;
  background-color: #fff;
  // position: relative;
  // z-index: -1;
  position: relative;
  z-index: -77;
  .footer-top {
    padding: 0px 114px 0 114px;
    position: relative;
    z-index: 99;
    .app-title {
      font-size: 36px;
      color: #464646;
    }
    .links-row {
      margin-top: 2 * $bigger-gap;
      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        .nav-link {
          padding: $base-gap 0px;
          color: #464646;
        }
        .nav-link:hover {
          color: $primary-green;
        }
        .download-text {
          font-size: $title-font;
          color: #464646;
        }
        .appstore-button {
          cursor: pointer;
          margin-top: $double-gap;
          height: 42px;
        }
      }
      .social-list {
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: $double-gap;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $normal-width;
            height: $normal-width;
            border-radius: $normal-width/2;
            background-color: rgba(0, 0, 0, 0.8);
            .icon {
              color: $white;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    // border-top: 0.5px solid $grey;
    position: relative;
    // z-index: 9;
    p {
      margin: 0px;
      color: #464646;
      opacity: 0.8;
      text-align: center;
    }
  }
}
@media screen and (max-width: $md-break) {
  footer {
    background: #f5f5f5;
    .footer-top,
    .footer-bottom {
      padding: $double-gap;
      .links-row {
        margin-top: $big-gap;
      }
    }
    .footer-top {
      .links-row {
        margin: 0px 0px 0px 0px;
        .download-section {
          margin: $big-gap 0px;
          .download-text {
            margin: 0px;
          }
          .appstore-button {
            margin-top: $small-gap;
          }
        }
        .social-list {
          display: flex;
          justify-content: space-evenly;
          li {
            margin: 0px;
          }
        }
      }
    }
  }
}

.landing-page {
  footer {
    padding-top: 0;
    z-index: 9;
    position: relative;
  }
}
