@import "../../Themes/colors";
@import "../../Themes/sizes";
.reset-password-page {
  .reset-form {
    padding: $bigger-gap;
    background-color: white;
    .info-wrapper {
      text-align: center;
      .success-message {
        color: $primary-green;
      }
    }
    form {
      margin-top: $double-gap;
      button {
        width: 100%;
      }
    }
    .reset-header-section {
      height: 100vh;
    }
    .reset-button-wrapper {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}
