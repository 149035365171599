@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.filter-bar {
  padding: $double-gap 0px;
  border: solid lightgrey;
  border-width: 1px 0px;
  .filter-item {
    cursor: pointer;
    display: inline-block;
    margin-right: $base-gap;
    padding: $small-gap/2 $base-gap;
    border: 1px solid lightgrey;
    border-radius: $small-radius;
    &:last-child {
      margin-right: 0px;
    }
  }
  .filter-Selected {
    background: $primary-green;
    color: white;
  }
}

@media screen and (max-width: $md-break) {
  .filter-bar {
    padding: $medium-gap 0px;
    .filter-item{
      margin-top: $small-gap;
    }
  }
}

@media screen and (min-width: $md-break) 
              and (max-width: $lg-break){
    .filter-bar {
      .filter-item{
        margin-top: $small-gap;
      }
    }
}
