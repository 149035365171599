// .ant-tabs-top.step-tabs.ant-tabs-line > div.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > div > div.ant-tabs.ant-tabs-top.ant-tabs-card.ant-tabs-no-animation > div.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar

.StyleDelvryAddrs{


.ant-tabs-content{
  h4{
    font-size: 20px;
    font-weight: 600;
    color: #3c484c;
    margin-bottom: 3px;
  }
  h4::after{
    content: "";
    border-bottom: 2px solid #66d687;
    padding-bottom: 5px;
    width: 9%;
    display: block;
  }
  .ShippingNew{
    width: 100%;
    float: left;
    display: block;
    margin-top: 1rem;
    margin-left: -39px;
    ul{
      list-style-type: none;
      li{
        a{
          svg{
            margin-right: 4px;
          }
         
         color: #6cc788;
         font-size: 14px;
         font-weight: 600;
         letter-spacing: 0.7px;
         width: unset;
         text-align: left;
         border-bottom: 1px solid;
         padding-bottom: 2px;
         }
        a:hover{
          text-decoration: unset !important;
        }
        a:focus{
          text-decoration: unset !important;
        }
      }
    }
  }
}
}
 .ant-tabs-nav-container{
   height: unset !important;
 } 
.StyleDelvryAddrs {
  .ant-tabs-ink-bar-animated, .ant-tabs-bottom .ant-tabs-ink-bar-animated {
  display: none !important;
  // color: red;
}

.ant-tabs .ant-tabs-card .ant-tabs-card-bar  {
  border-bottom: 0px;
  

}

.ant-tabs-nav-wrap {

  width: auto;
  float: left;
  display: flex;
  background: #e4e4e4;
  
  border-radius: 5px;
  padding: 2px 2px;

  border-radius: 7px;



  .ant-tabs-nav {
    .ant-tabs-tab {
      background: unset !important;
      color: #575757;
      
    }

  .ant-tabs-tab-active {
    color: #6cc788 !important;
    background: #fff !important;
    border-radius: 6px;
  }

}
}
}
.ant-tabs-bar {
  // padding-bottom: 12px;
}
 
/* Responsive css */
@media screen and (max-width: 768px) {
  .ant-tabs-bar {
    .ant-tabs-nav-container {
      .ant-tabs-nav-wrap{
        width: auto;
      }
    }
  }
}

