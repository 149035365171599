@import "../../../Themes/colors";
@import "../../../Themes/sizes";
.forgot-password {
  padding: $big-gap;
  .success-info {
    p span {
      color: $primary-green;
    }
  }
  .send-button-wrapper {
    text-align: center;
    .send-button {
      width: 100%;
    }
  }
  .back-link {
    text-align: center;
    margin-top: 32px;
  }
}
