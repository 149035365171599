@import "../../../Themes/sizes";
@import "../../../Themes/colors";


.container {
    display:flex;
    justify-content:flex-end;
    align-items:center; 

    .button {
        background-color: transparent;
        margin: 0px;
        padding: 10px;
        color: $grey;
        min-width: 30px;
        
    }

    .apply {
        color: $primary-green;

    }
}