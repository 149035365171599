@import "../../Themes/colors";
@import "../../Themes/sizes";

.user-profile {
  position: absolute;
  height: 100%;
  z-index: 99;

  .user-Profile-Content {
    margin-bottom: 100px;
    .user_profile_tab {
      // padding-left: $hh-padding;
      .ant-tabs-nav-container {
        background-color: $primary-green;
        padding-left: $hh-padding;
        padding-right: $hh-padding;
        .ant-tabs-nav {
          .ant-tabs-tab {
            color: $alphaWhite;
            font-weight: 300;
          }
          .ant-tabs-tab:hover {
            color: $white;
            font-weight: 600;
          }
          .ant-tabs-tab-active {
            color: $white;
            border-bottom-color: $white;
            font-weight: 600;
          }
          .ant-tabs-ink-bar {
            background-color: $white;
            min-width: 88px;
            height: 4px;
          }
        }
      }
      .ant-tabs-top-content {
        .ant-tabs-tabpane:first-child {
          //   margin-left: $hh-padding;
          //   margin-right: $hh-padding;
        }
      }
    }
  }

  @media screen and (max-width: $md-break) {
    // .ant-tabs {
    //     .ant-tabs-content {
    //         padding: 0 $double-gap;
    //     }
    // }
    // .ant-tabs-bar{
    //     padding: 0 $double-gap;
    // }
    .user-Profile-Content {
      .user_profile_tab {
        // padding-left: $hh-padding;
        .ant-tabs-nav-container {
          padding-left: $double-gap;
          padding-right: $double-gap;
          .ant-tabs-tab-arrow-show {
            .ant-tabs-tab-next-icon {
              i {
                color: #fff;
              }
            }
            .ant-tabs-tab-prev-icon {
              i {
                color: #fff;
              }
            }
          }
        }
        .ant-tabs-top-content {
          .ant-tabs-tabpane:first-child {
            margin-left: $double-gap;
            margin-right: $double-gap;
          }
        }
      }
    }
  }
}
