@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.placeholder-header {
  .custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $p-header-height;
    &.home {
      padding: 0px $p-header-padding;
      .nav-link-list {
        padding-left: 0px;
      }
    }

    .title {
      cursor: pointer;
      margin: 0px;
      font-weight: bold;
      font-size: $title-font;
    }
    .nav-link-list {
      list-style: none;
      margin: 0px;
      padding: 0px;
      padding-left: $p-header-padding;
      a {
        color: black;
        font-size: $normal-font;
        text-transform: uppercase;
        li {
          display: inline-block;
          padding: $base-gap;
        }
      }
      li.active {
        border-bottom: 5px solid $primary-green;
      }
    }

    .ios-app-link img {
      height: 42px;
      object-fit: contain;
    }
    .goto-top {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 100%;
      background-color: $primary-orange;
    }
  }
}

@media screen and (max-width: $md-break) {
  .placeholder-header {
    .custom-header {
      display: none;
    }
  }
}
