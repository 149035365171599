.ShippingNew{
    .content{
        .model{
            width: 600px;
            height: auto;
            overflow-y: scroll;
            .model-Content {
                form{
                    width: 100%;
                    float: left;
                    display: block;
                    .form-group{
                        .form-check{
                            label{
                                margin-left: 6px;
                                color: #58a46f;
                                font-size: 15px;
                                margin-top: 1px;
                                font-weight: 600;
                            }
                        }
                        margin-top: 1rem;
                        label{
                            font-weight: 500;
                            color: #555;
                        }
                        input{
                            border-color: #767676;
                        }
                        select{
                            border-color: #767676;
                        }
                    }
                    .SaveShipAddress{
                        font-size: 15px;
                        font-weight: 600;
                        border: 1px solid #58a46f;
                        color: #fff;
                        background: #58a46f;
                        height: 40px;
                        display: block;
                        width: 100%;
                        border-radius: 6px;
                        text-align: center;
                        line-height: 40px;
                        margin-bottom: 1rem;
                    }
                    .SaveShipAddress:hover{
                        text-decoration: unset;
                    }
                }
                padding: 20px 27px;
                h3{
                    font-size: 20px;
                    font-weight: 600;
                    color: #2e2e2e;
                    border-bottom: 2px solid #9ae2b0;
                    padding-bottom: 5px;
                }
            }
        }
      
    }
}

@media screen and (max-width:768px){
    .ShippingNew { 
        .content{
        .model{width: unset;}
    }
}
}