@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.container-category {
    max-width: 600px;
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: $double-gap 0px;
        .item {
            border-radius: $small-radius;
            padding: 10px;
            color: #8C8C8C;
            background-color: rgba(#8A8B94, 0.12);
            cursor: pointer;
        }
        .selected-condition {
            background-color: $primary-green;
            color: white;
        }
    }
}