@import '../../../Themes/colors';
@import '../../../Themes/sizes';

.user-Personal-Detail {
    width: 100%;
    
   

    .user-Personal-Detail-model {
        min-width: 300px;
        width: 20%;
        height: 350px; 
        margin: auto;
        // margin-left:30%; 

        .model{
            min-width: 320px;
        }
            .modal-head{
                border-bottom: white;
                .modal-title {
                    margin: 0px auto;
                    h6{
                        color: #464646;
                    }
                }
            }
            .modal-body{
                .modal-btn{
                    background-color: #f9f9f9;
                    color: #6CC788;
                    margin: 10px auto;
                    border: 1px dashed #6CC788;
                    width: 60%;
                }
            }

        .user-Personal-Detail-form {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            .line-Styling {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                .text {
                    color: black;
                    font-size: 0.7rem;
                    text-align: center;
                }
                .line {
                    height: 0px;
                    border: 0.5px solid #979797;
                    width: 30%;
                    margin: 0px 5px;
                    opacity: 0.16;
                }
            }
            .facebook_Button {
                margin-top: 20px;
                background-color: transparent;
                border: 2px solid #3b5998;
                border-radius: 0;
                font-weight: 700;
                color: #3b5998;
                width: 60%;
                min-width: 100px;
            }
            .user-Personal-Detail-bday {
                width: 100%;
                display: flex;
                margin: 10px 0;
                flex-direction: row;
                justify-content: space-between;
                .ant-select-selection--single {
                    background-color: #F5F5F6;
                }
                .ant-calendar-picker-input {
                    background-color: #F5F5F6;
                }
                .anticon{
                    display: none;
                }
                .two-items {
                    width: 45%;
                }
            }
            .form-itemContainer {
                width: 100%;
                margin: 10px 0;
                .user-Personal-Detail-item {
                    border-color: #DEE2E5;
                    background-color: #F5F5F6;
                }
                .error {
                    color: red;
                }
                .hide {
                    display: none;
                }
            }
        }
        
    }
}


@media screen and (max-width:400px){
    .user-Personal-Detail {

        .model{
            min-width: 280px !important;
        }
        .user-Personal-Detail-model {
            margin-left: 16px !important;
        }
    }
}
@media screen and (max-width:320px){
    .user-Personal-Detail {

        .user-Personal-Detail-model {
            margin-left: -8px !important;
        }
    }
}


@media screen and (max-width: $sm-break) {
    .user-Personal-Detail {
        .user-Personal-Detail-model {
            margin-left:10vw;
        }
    }
}

@media screen and (min-width: $sm-break) and (max-width: $md-break) {
    .user-Personal-Detail {
        .user-Personal-Detail-model {
            margin-left:10vw;
        }
    }
}
  
@media screen and (min-width: $md-break) and (max-width: 900px){
    .user-Personal-Detail {
        .user-Personal-Detail-model {
            margin-left:15vw;
        }
    }
}