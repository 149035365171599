@import "../../../Themes/sizes";
@import "../../../Themes/colors";
.section {
  margin-bottom: 30px;
  width: 100%;
  height: 100vh;
  min-height: 650px;
  .placeholder-section-container {
    width: 100%;
    height: 100%;
  }
}
