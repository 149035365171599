@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.container-condition {
  max-width: 450px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: $double-gap 0px;
    .item {
      border-radius: $small-radius;
      padding: 10px;
      color: #8c8c8c;
      background-color: rgba(#8a8b94, 0.12);
      cursor: pointer;
    }
    .selected-condition {
      background-color: $primary-green;
      color: white;
    }
  }
}
