@import "../../../Themes/colors";
@import "../../../Themes/sizes";
.login-page {
  form {
    .login-button-wrapper {
      text-align: center;
      .login-button {
        width: 100%;
        border-radius: $small-radius;
      }
    }
    .forgot-password-text {
      padding: 0px;
      text-align: center;
      font-size: $normal-font;
    }
  }

  .terms{
    margin-top: 2%;
    text-align: center;
    font-size: 0.9rem;
    .link-css{
      color: $primary-green;
      cursor: pointer;
    }
  }
}
