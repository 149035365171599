@import "../../Themes/colors";
@import "../../Themes/sizes";

.product-details {
  .page-container {
    padding-bottom: $big-gap !important;
  }
  .spinner{
    margin-left: 47%;
    margin-top: 200px;
}

  .carousel-wrapper {
    width: 100%;
    padding-top: 100%;

    .product-images {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .slick-list,
      .slick-track,
      .slick-slide,
      .slick-slide div {
        height: 100%;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .top-wrapper{
    height: 60%;
  }
  .description {
    padding-left: $big-gap;

    .name {
      margin: 0px;
    }

    .rate-table {
      margin: $big-gap 0px;

      th {
        font-size: $heading-font;
        padding-right: $big-gap;
      }
    }
  }



  .map-wrapper {
    margin: $big-gap 0px;
    margin-bottom: $header-height;
    height: 40%;
    .map{
      height: 300px;
    }
  }
}

@media screen and (max-width: $md-break) {
  .product-details {
      .description {
      padding-left: 0px;
      padding-top: $big-gap;
    }
  }
}

@media screen and (min-width: $md-break) and (max-width: 900px){
  .product-details {
    .description {
      padding-left: 10px;
    }
  }
}
@media screen and ( min-width: 768px ) and (max-width: 1023px){
  .product-details {
        min-height: 105vh;
  }
}


@media screen and (min-height: 1100px) {
  .product-details {
    .description 
    {
      .rate-table th {
        font-size: 30px;
        padding-right: 30px;
      }
    }
    .map-wrapper {
      .map{
        height: 600px;
      }
    }
  }
}
