@import '../../../Themes/colors';
@import '../../../Themes/sizes';

.custom-button {
    cursor: pointer;
    padding: 0px $double-gap;
    background-color: $primary-green;
    color: $white;
    font-size: $big-font;
    min-width: $button-width;
    min-height: $button-height;
    line-height: $button-height - 2*$small-gap;
    border-radius: $normal-radius;
    border: none;
    outline: none;
}

.custom-button.outline {
    line-height: $button-height - 2*$small-gap -2px;
    color: $black;
    border: 1px solid black;
    background-color: $white;
}