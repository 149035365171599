@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.checkout-steps {
  .ant-tabs{
    padding-top:11px;
  }
  margin-right: $bigger-gap;
  //  .ant-tabs-top-content {
  //  padding-top: 10px;
  //  }
  .step-tabs {
    min-height: 100%;
    .step-nav-menu {
      li {
        text-transform: uppercase;
        font-weight: 600;
        &:hover {
          color: inherit;
          border-bottom-color: inherit;
        }
        &.ant-menu-item-selected {
          color: $primary-green;
          border-bottom-color: $primary-green;
        }
      }
    }
  }
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    .continue-button {
      min-width: 40%;
    }
    .back-button {
      cursor: pointer;
      font-size: $big-font;
      border: none;
      outline: none;
      background-color: transparent;
      .back-arrow {
        margin-right: $base-gap;
      }
    }
  }
}

@media screen and (max-width: $md-break) {
  .checkout-steps {
    margin-right: 0px;
    margin-top: $bigger-gap;
  }
}

@media screen and (max-width: $sm-break) {
  .checkout-steps {
    .nav-buttons {
      flex-direction: column-reverse;
      .back-button {
        margin-top: $double-gap;
      }
    }
  }
}
