@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.manage-post {
  .cards-product {
    width: 200px;
  }
  .post-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1500px;
    margin: auto
  }
}

@media screen and (max-width: 400px) {
}
@media screen and (max-width: 320px) {
}
@media screen and (max-width: $sm-break) {
}
@media screen and (min-width: $sm-break) and (max-width: $md-break) {
}
@media screen and (min-width: $md-break) and (max-width: 900px) {
}
