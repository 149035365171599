@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.userinfo-conatiner {    
    padding-top: 10px;
    padding-bottom: 10px;
    .userinfo-content {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-style: solid;
        border-bottom-width: thin;
        padding-bottom: 20px;
        border-color: #979797;
        .avatar {
            height: 80px;
            width: 80px;
            margin-top: 10px;
            .avt-word{
                border-radius: 50%;
                height: 50px;
                width: 50px;
                background-color: #eeeeee;
                text-align: center;
                padding-top: 5px;
            }
            img {
                height: 80px;
                width: 80px;
                border-radius: 50%;
            }
        }
        .information {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            h5, p {
                margin: 0px;
                padding: 0px;
            }
            .favorite-button {
                cursor: pointer;
                position: absolute;
                top: $base-gap;
                right: $base-gap;
                z-index: 999;
              }
            .Write-msg{
                margin-top: 10px;

            p {
                color: #6CC788;
                cursor: pointer;
            }
            }
            
        }
    }
}