@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.cart{
    .ant-row{
        margin: 0%;
    }
    .spinner{
      margin: auto;
  }
    .content .model {
        width: 500px;
        min-height: 250px;
        .modal-content{
          width: 500px;
          .modal-head-title{
            padding: 0% 2%;
          }
          .modal-body{
            scroll-behavior: auto;
            overflow-x: hidden; 
            overflow-y: scroll;
            padding-left: 5%;
            padding-right: 5%;  
            height: 250px;
            .cart-product{  
                padding: 2% 0%;
            .cart-Img{
                height: 100px;
                width: 100px;
            }
            .cart-info{
                padding: 2% 0%;
                .rate{
                    color: $primary-green;
                }
                h4{
                    font-size: 16px;
                    line-height: 22px;
                    background: white;
                    padding: 0%;
                    text-align: left;
                    margin: 0%;
                }
                h6{
                    font-size: 12px;
                    line-height: 18px;
                    color: #8C8C8C;
                }
            }
            .remove{
                color: #FF9A8A;
                margin-top: 45px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                cursor: pointer;
            }
          }
        }
              .amount{
                padding-left: 5%;
                width: 100%;
                .total{
                  color: $primary-green;
                }
            }

            .checkout-sec{
              .ant-row{
                margin: auto;
              }
          }
        }
        .modal-btn{
          margin-bottom: 20px;
        }
      }
}
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content .anticon{    top: -3px;}

@media screen and (max-width: $sm-break) {
    .cart{
        .content .model {
            width: 320px;
            flex-direction: row;
          .modal-content{
            width: 100% !important;
            .modal-body{
                .cart-Img{
                    height: 60px !important;
                    width: 60px !important;
                }
                .cart-info{
                    h4{
                        font-size: 14px !important;
                    }
                    h6{
                        font-size: 10px !important;
                    }
                }
                .remove{
                    margin-top: 25px !important;
                    font-size: 12px !important;
                }
              }
            .ant-row div{
              font-size: 15px;
            }
          }
        }
      }
    }
      