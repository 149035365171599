@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.custom-carousel {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;

  .carousel-images {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div {
      height: 100%;
    }

    .slick-dots {
      height: auto;
      bottom: $small-gap;
      .slick-active {
        button {
          background-color: $primary-green;
        }
      }

      button {
        width: $tiny-size + 5px;
        height: $tiny-size + 5px;
        border: 1px solid white;
        background-color: rgba(100, 100, 100, 0.2);
        opacity: 1;
        border-radius: ($tiny-size + 5px)/2;
      }
    }

    .carousel-image {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
      //width: unset !important;
      //transform: unset !important;
    }
    .carousel-image-Full {
      object-fit: contain;
    }
  }
}


