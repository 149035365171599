@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.product-action-bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0px;
  bottom: 0px;
  width: 100%;
  min-height: $header-height;
  padding: $base-gap $hh-padding;
  background-color: $white;
  box-shadow: -2px 0px 6px $grey;
  box-sizing: border-box;
  z-index: 999;

  .owner-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    h4 {
      margin: 0px;
      margin-right: 5px;
    }

    .thumbnail {
      margin-right: $double-gap;
      width: $large-width;
      height: $large-width;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $large-width/2;
      }
      h1 {
        width: 100%;
        height: 100%;
        text-align: center;
        color: white;
        line-height: $large-width;
        font-size: $heading-font;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: $large-width/2;
        background-color: $primary-green;
      }
    }
    // .message-buttons {
      
      
    // }
  }
  

  .share-buttons,
  .action-buttons {
    display: inline;
    .action-button {
      cursor: pointer;
      margin-left: $double-gap;
    }
    .hide-button{
      visibility: hidden;
    }
  }
}

@media screen and (min-width: $md-break) and (max-width: $xl-break) {
  .product-action-bar {
    padding: $base-gap $hh-padding/2;
  }
}

@media screen and (max-width: $md-break) {
  .product-action-bar {
    flex-wrap: wrap;
    padding: $base-gap $double-gap;
    .owner-info {
      display: none;
    }

    .button-wrapper {
      width: 100%;
      .share-buttons {
        display: none;
      }
      .action-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .action-button {
          margin: 0px;
        }
      }
    }
  }
}
