@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.container-UserRating {
    
    

    .wrapper-UserRating {
        padding: $double-gap 0px;
        .item-UserRating {
            display: inline;
            margin-right: $base-gap;
            padding: $small-gap $base-gap;
            border-radius: $small-radius;
            color: #8C8C8C;
            background-color: rgba(#8A8B94, 0.12);
            border: none;
            cursor: pointer;
            &:last-child {
                margin-right: 0px;
              }
        }
        .selected-UserRating {
            
            color: $primary-green;
            border: 1px solid $primary-green;
        }
    }
}