@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.msg{
    text-align: center;
    padding: 10% 0px 0px;
    flex-grow: 1;
    .no-msg{
        margin: auto;  
     }
.msg-default{
    word-spacing: 0.2;
    letter-spacing: 28;
}
.custom-button {
    margin:  20px 0px 0px;
}
}



@media screen and (max-height: 818px)
and (min-width:40rem){
    .msg{
        padding: 2% 0px !important;
    }
}

@media screen and (max-height: 625px)
and (max-width:320px){
    
    .msg{
        padding: 0px !important;
        .custom-button{
            margin: 0px !important;
        }
    }
}

@media screen and (max-width: 40rem){
    .msg{
        .no-msg{
            height: 20vh;
        }
        .custom-button{
            font-size: 1rem;
        }
    }
}


@media only screen 
and (min-width : 40rem) 
and (max-width : $md-break)  {
    .msg{
        .no-msg{
            height: 18vh;
            
        }
        .custom-button {
            padding: 15px;
            font-size: 1.5rem;
        }
    }
}


@media only screen 
and (min-width : $md-break) 
and (max-width : 900px)  {
    .msg{
        .no-msg{
            height: 25vh;
            
        }
        .custom-button {
            padding: 15px;
            font-size: 1.5rem;
        }
    }
}

@media only screen 
and (min-width : 900px) 
and (max-width : 1024px)  {
    .msg{
        .no-msg{
            height: 30vh;
            
        }
        .custom-button {
            padding: 15px;
            font-size: 1.5rem;
            margin-top: 40px;
        }
    }
}