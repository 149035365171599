@import "../../Themes/sizes";
@import "../../Themes/colors";

.policy-page {
  position: absolute;
  height: 100%;
  .policy-container {
    padding-top: $bigger-gap;
    text-align: justify;
    z-index: 1;
    position: relative;
    .title {
      margin-bottom: $bigger-gap;
      font-weight: bold;
      text-align: center;
    }
    .instructions {
      text-align: center;
      font-weight: bold;
      font-size: $big-font;
      margin-bottom: 2rem;
    }
    p{
      font-size: 13px;
    }
    h4{
      font-weight: 600 ;
      font-size: 16px;
    }

    p,
    ol li {
      font-weight: 500;
      font-size: $big-font;
    }
  }
}
