@import "../../../Themes/sizes";
@import "../../../Themes/colors";


.cart-checkout{
  min-height: 113vh;
}
.checkout-cart {
  padding: $bigger-gap;
  background-color: #f8f8f8;
  hr {
    margin: $double-gap auto;
  }
  .cart-title {
    font-size: $biggest-font;
    margin: 0px;
  }
  .cart-products {
    padding: $double-gap 0px;
    .cart-product {
      display: flex;
      .product-image {
        min-width: 1.5 * $normal-size;
        width: 1.5 * $normal-size;
        height: 1.5 * $normal-size;
        object-fit: cover;
        margin-right: $base-gap;
        border: 1px solid lightgrey;
      }
      .product-details {
        display: flex;
        flex-direction: column;
        .product-name {
          flex: 1;
          margin: 0px;
          font-size: $bigger-font;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .product-price {
          font-size: $big-font;
          font-weight: 600;
        }
      }
    }
  }
  table {
    width: 100%;
    tr {
      td {
        padding: $small-gap 0px;
        font-size: $big-font;
        &:last-child {
          text-align: right;
          font-weight: bold;
        }
      }
    }
    &.total-price {
      tr {
        td {
          &:last-child {
            color: $primary-green;
          }
        }
      }
    }
  }
}

@media screen and (min-width:320px) and (max-width:767px) {
  .checkout-cart{
    .cart-products{
      .cart-product{
        margin-bottom: 13px;
      }
    }
  }
}

@media screen and (max-width: $sm-break) {
  .checkout-cart {
    padding: $double-gap;
  }
}
