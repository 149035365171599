.content {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0px;
    background-color:rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    
    .model { 
        position: relative;      
        min-height: 50px;
        min-width: 50px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 12px;
    .model-Content {
        position: relative;
        .cross {
            position: absolute;
            top: 5px;
            left: 10px;
            min-width: 40px;
            min-height: 40px;
            background-color: transparent;
            font-size: 1.5rem;
            color: black;
        }
            h4 {
                text-align: center;   
                width: 100%;
                padding: 12px;
                background-color: rgba(77, 77, 77, 0.1);  
            }
            .avtar {
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: row;
                h1 {
                    margin : 0;
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                    background-color: #f5f5f5;
                    text-align: center;
                    padding: 5px;
                }
                
                img {
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                    
                }
                .text {
                    padding-left: 10px;
                    font-size: 1.5rem;
                    color: black;
                    font-weight: 400;
                    
                    }
            }
            .Notification_Detail {
                min-height: 50px;
                color: black;
                background-color: rgba(77, 77, 77, 0.1);
                padding: 10px;
                margin: 10px;
                p {
                    font-weight: 500;
                }

            }
            
            .bottom_buttons {
                margin: 10px 0px;
                
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                .button {
                    min-width: 40%;
                }

                .decline {
                    background-color: #FF9A8A;
                }
            }
    }

    
}
}