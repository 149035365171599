@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";
#about {
  position: relative;
    z-index: 100;
  .about-container {
    width: 100%;
    height: 100%;
    
    
    padding: 0px $p-header-padding;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 30%;
    .info {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin: $bigger-gap auto;
      h2 {
        position: relative;
        float: left;
        width: 50%;
        font-size: $title-font;
        font-size: 600;
        z-index: 99;
        line-height: 35px;
        &::after {
          content: "About";
          position: absolute;
          top: -2 * $big-gap;
          right: 2 * $bigger-gap;
          color: lightgrey;
          font-weight: 900;
          font-size: 68px;
          text-transform: uppercase;
          writing-mode: vertical-rl;
          text-orientation: mixed;
          opacity: 0.4;
          z-index: -1;
        }
      }
      img {
        width: 300px;
        object-fit: contain;
      }
    }
    .description {
      p {
        position: relative;
        float: right;
        width: 60%;
        line-height: 24px;
        font-size: $normal-font;
      }
    }
  }
}



  @media screen and (max-width: 320px) {

    #about{
      .about-container{
         .info{
          margin: 25px auto;
          h2{
            line-height: 19px !important;
            font-size: 13px !important;
          }
         }
         .description{
           p{
            font-size: 12px;
           }
         }
      }
    }
  }


@media screen and (max-width: $md-break) {
  #about {
    .about-container {
      display: flex;
      flex-direction: column;
      padding: $base-gap;
      background-size: 90%;
      .info {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        h2 {
          width: 100%;
          font-size: $bigger-font;
          text-align: justify;
          line-height: 25px;
          &::after {
            display: none;
          }
        }
        img {
          width: 70%;
        }
      }
      .description {
        p {
          width: 100%;
          line-height: 19px;
          font-size: $small-font;
        }
      }
    }
  }
}
