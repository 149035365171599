@import "../../Themes/sizes";
@import "../../Themes/colors";

.placeholder-container {
  .mobile-menu {
    display: none;
  }
}

@media screen and (max-width: $md-break) {
  .placeholder-container {
    .mobile-menu {
      display: block;
      .menu-icon {
        position: fixed;
        top: $base-gap;
        right: $base-gap;
        padding: $small-gap;
        border-radius: $normal-radius;
        background-color: white;
        box-shadow: 0px 1px 5px grey;
        z-index: 999;
        &.open {
          box-shadow: none;
        }
        svg {
          font-size: $small-size;
        }
      }
      .nav-links {
        position: fixed;
        top: 0px;
        left: 0px;
        padding-top: 2 * $bigger-gap;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 99;
        &.open {
          transform: translateX(0%);
          animation: slideIn 0.2s linear;
        }
        &.close {
          transform: translateX(100%);
          animation: slideOut 0.2s linear;
        }
        ul {
          margin: 0px;
          padding: 0px;
          li {
            padding: $double-gap 10%;
            font-size: $big-font;
            font-weight: 600;
            list-style: none;
            &:active {
              background-color: lightgrey;
            }
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    display: none;
    transform: translateX(100%);
  }
  to {
    display: block;
    transform: translate(0%);
  }
}

@keyframes slideOut {
  from {
    display: block;
    transform: translateX(0%);
  }
  to {
    display: none;
    transform: translate(100%);
  }
}
