@import "../../Themes/colors";
@import "../../Themes/sizes";

.Like{    
    height: 100vh;
    margin: 0;
    padding: 0%;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    .row{
        margin: 0;
    }
    .Like-pg{
        min-height: 0;
        margin: 0%;
        .page-container {
            padding:unset;
          }
    }

    .new-section{
        padding: 0px 114px; 
        width: 100%;
        border-bottom: 0.5px solid #D8D8D8;
        .Head-section{
            h1{
                font-size: 32px;
                font-weight: 600;
                line-height: 39px;
                margin-bottom: 30px;
            }

            .Head-span{
                float: right;
                color: $primary-green;
                font-weight: 400;
            }
        }
    }
      
    .mid-section{
        min-height: 34vh;
        padding: 0px 114px; 
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
        .product-grid {
            display: grid;
            grid-gap: $big-gap;
            grid-template-columns: repeat(5, 1fr);
            .AdjustingHeight{
                width: 190px;
                margin-bottom: 26%;
                .ant-card {
                    min-height: 100%;
                    margin-bottom: 12px;
                    .Add-btn{
                        background-color: $white;
                        color: #6CC788;
                        margin: 13px 7%;
                        font-size: 2vh;
                        box-shadow: 0.1px 0.1px 2px #aaaaaa !important;
                        margin-top: 15px;
                    }
                    .Add-btn:hover{
                        box-shadow: 0.1px 0.1px 2px #aaaaaa !important;
                        
                    }
                }

           
            }

          }
    }

    
    .footer-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 88px;
        border-top: 0.5px solid #D8D8D8;
        p {
          margin: 0px;
          text-align: center;
        }
    }
}

@media screen and (min-width: 300px) and (max-width: 767px) { 
    // .Like{
    //     .Add-btn{
    //         margin: 10px -2px !important;
    //     }
    // }
}

@media screen and (min-width: 300px) and (max-width: 400px) { 
    .Like{ 
        .page-container {
          padding: $double-gap;
        }
        .new-section{
            padding: $double-gap;
        } 
        .mid-section{
            padding: $double-gap;
            .product-grid {
                display: grid;
                grid-gap: $base-gap;
                grid-template-columns: repeat(2, 1fr);
                .AdjustingHeight{
                    margin-bottom: 39%;
                }
              }
            }
        .Add-btn{
            margin: 0px 0px !important;
        }
    }
}

@media screen and (min-width: $md-break) and (max-width: $xl-break) {
    .Like {
        .page-container {
          padding: $hh-padding/2;
        }
        .new-section{
            padding: $hh-padding/2;
        }
    }
  }


  @media screen and (min-width: 400px) and (max-width: $md-break) {
    .Like { 
        .page-container {
          padding: $double-gap;
        }
        .new-section{
            padding: $double-gap;
        } 
        .mid-section{
            padding: $double-gap;
            .product-grid {
                display: grid;
                grid-gap: $base-gap;
                grid-template-columns: repeat(2, 1fr);
                .product-card{
                    .product-cover{
                        height: 180px;
                        .custom-carousel{
                             .carousel-images{
                                .carousel-image{
                                    height: 180px;
                                }
                             } 
                        }
                    }
                }
              }

              .Add-btn{
                margin: 20px 25px !important;
            }
        }
    }
  }


  @media screen and (max-width: 800px) and (min-width: 767px){
    .Like{
        .mid-section {
            .Add-btn {
                margin: 20px 100px !important;
            }
        }
    }
  }