@import "../../../Themes/colors";
@import "../../../Themes/sizes";

.floatButton {
    // background-color: red;
    display: none !important;
    position: fixed;
    bottom: $header-height + 10;
    z-index: 1000;
    right: 20px;    
}

@media screen and (max-width: $md-break) {
    .floatButton {
        display: flex !important;
    }
}