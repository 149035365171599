@import "../../../Themes/sizes";
@import "../../../Themes/colors";

.cartDate{
    .ant-row{
        margin: 0%;
    }
    .content .model {
        .modal-content{
          width: 580px;
          .modal-body{
            padding: 0%;
          }
          .modal-footer{
            .ant-row{
              margin: auto;
            }
          }
        }
        .modal-btn{
          margin-bottom: 20px;
        }
      }
}

@media screen and (max-width: $md-break) {

.cartDate{
      .content .model {
        .modal-content{
          width: 100% !important;
          .ant-row div{
            font-size: 10px;
            text-align: center;
          }
        }
      }
    }
  }