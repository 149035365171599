@import "../../../../Themes/sizes";
@import "../../../../Themes/colors";
#contact {
  .contact-container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px $bigger-gap 0px $p-header-padding;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 60%;
      height: 100%;
      background-color: $primary-green;
    }
  }
  .form-wrapper {
    position: relative;
    float: left;
    width: 30%;
    .social-link-wrapper {
      display: flex;
      justify-content: space-between;
      margin: $bigger-gap auto;
      ul {
        padding: 0px;
        li {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: $base-gap;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            border-radius: 21px;
            background-color: $white;
            .icon {
              color: $black;
              width: $tiny-size * 1.5;
              height: $tiny-size * 1.5;
            }
          }
        }
      }
      img.ios-link {
        height: 42px;
        object-fit: contain;
      }
    }
    .copy-right {
      margin: 0px;
      color: white;
    }
  }
  .contact-image {
    position: relative;
    float: right;
    width: 50%;
    object-fit: contain;
  }
}

@media screen and (max-width: $md-break) {
  #contact {
    .contact-container {
      padding: $base-gap;
      &::before {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
      }
      .copy-right {
        text-align: center;
      }
    }
    .contact-image {
      display: none;
    }
  }
}
